import React, { useState, useRef, useEffect, useCallback } from "react";
import { CSVLink } from "react-csv";
import { Spinner } from 'react-bootstrap'
import "./linechart.scss";
import Plot from "react-plotly.js";
import ip from "../config_ip";
import Viewbox from "./UI/Viewbox";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form.jsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter } from '@/components/ui/dialog.jsx';
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent
} from '@/components/ui/tabs.jsx';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { Button } from "@/components/ui/button.jsx";
import { Input } from "@/components/ui/input.jsx";
import Telemetrics from "./UI/AreaTelemetrics";
import Allfetch from "./Allfetch";
import { useAllFetch } from "./CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";

import { Loading } from "./UI/Loading";
import NoResults from "./UI/NoResults";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.jsx";
import { useTranslation, Trans } from "react-i18next";

const user = localStorage.getItem('name')
const contractor_id = localStorage.getItem('contractor_id')
const arraySlash = window.location.pathname.split('/')
const jobId = arraySlash[2]
const areaId = arraySlash[3]

export default function Linegraph(props) {

  const { t } = useTranslation()
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };

  const [veriEmail, setVeriEmail] = useState("")
  const [areaPictures, setAreaPictures] = useState([])
  const [loading, setLoading] = useState(false)
  const fileInputRef = useRef();
  const { getToken } = useAuth()

  const area = useAllFetch(['area'], '/getarea/' + areaId)
  const events = useAllFetch(['events'], '/getevents/' + areaId)
  const daily = useAllFetch(['daily'], '/daily/' + jobId + '/' + areaId)
  const current = useAllFetch(['current'], '/current/' + jobId + '/' + areaId)

  const changeStatusF = async (status, jobid, areaid, currentener, nada, why) => {
    const tokenu = await getToken()
    let type
    let why1
    if (why === undefined && status !== 'F') {
      type = 'R'
      why1 = 'Restart area'
    } else {
      type = status
      why1 = why
    }
    if (status !== 'F') {
      await fetch(ip.url + "/events", {
        method: "post",
        body: JSON.stringify({ areaid: areaid, why: why1, user: user, type: type }),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
    }
    var response = await fetch(ip.url + "/changeStatus/" + status + "/" + jobid + "/" + areaid, {
        method: "get",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`,
      }
    })
    response = await response.json()
    if (response.claims !== 'finish') {
      window.location.pathname = "/lareas/" + jobId
    } else {
      setVeriEmail(response.data?.email)
      sendMail(jobid, areaid, response.data?.address, response.data?.email, currentener, response.data?.emailc)
      window.location.pathname = "/jobs/" + contractor_id
    }
  }

  const fetchPictures = useCallback(async () => {
    const tokenu = await getToken()
    try {
      const response = await fetch(ip.url + "/getPictures/" + jobId + "/" + areaId, {
        method: 'GET',
        headers: {
          'Authorization': `bearer ${tokenu}`
        }
      });
      const result = await response.json();
      setAreaPictures(result.data);
    } catch (error) {
      console.error("Error fetching claim pictures:", error);
    }
  }, [getToken]);


  useEffect(() => {
    fetchPictures()
  }, [fetchPictures])

  const sendMail = async (idjob, areaid, address, emailc, currentener, contractor_email) => {
    Allfetch('/send-email/' + idjob + "/" + areaid + "/" + address + "/" + emailc + "/" + currentener + "/" + contractor_email + "/" + navigator.language.split('-',1)[0], veriEmail, null, null, await getToken())
  }

  const handleDelete = (deletedFile) => {
    setAreaPictures(areaPictures.filter((file) => file.filename !== deletedFile.filename));
  };

  const uploadPicture = async (file) => {
    const tokenu = await getToken()
    const formData = new FormData();
    formData.append("picture", file);
    try {
      const response = await fetch(ip.url + "/uploadPicture/" + jobId + "/" + areaId, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${tokenu}`
        }
      });

      await response.json();
      //console.log(result);
      fetchPictures();
      setLoading(false)
    } catch (error) {
      console.error("Error uploading picture:", error);
    }
  };

  const handleFileChange = (e) => {
    setLoading(true)
    const file = e.target.files[0];
    if (file) {
      uploadPicture(file);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  let humidata = [];
  let tempdata = [];
  let datedata = [];
  let amperdata = [];
  let powerdata = [];
  let energydata = [];
  let moisturedata = [];

  let timeelapsed = 0;

  let currenttemp = 0;
  let currenthumi = 0;
  let currentamper = 0;
  let currentpower = 0;
  let currentenergy = 0;

  let currentdate = "";
  let firstdate = [];
  let lastdate = [];
  let setpoint = 0.0;
  let amperage_expected = 0.0;
  let date_drying = "";

  let RH = 0;
  let t1 = 0;
  let H = 0;
  let dewpoint = 0;
  let pausedate = [];
  let pausewhy = [];
  // use the react query hook area to get the data from the api
  // wait for the data to be fetched before rendering the component
  // if the data is not fetched yet, show the loading componen
  if (!area.isLoading) {
    setpoint =  area.data?.humisetpoint
    amperage_expected = area.data?.amperage_expected
    date_drying = area.data?.date_drying
  }
  
  if (daily.data !== undefined) {
    const data = daily.data
    for (let i = 0; i < Object.keys(data).length; i = i + 1) {
      if (data !== undefined) {
        var fechaLocal = new Date(data[i].date + 'Z')
        datedata.push(fechaLocal);
        humidata.push(data[i].humi);
        tempdata.push(data[i].temp);
        amperdata.push(data[i].amper_ef);
        powerdata.push(data[i].power / 1000);
        energydata.push(data[i].energy);
        moisturedata.push(data[i].moisture);
      }
      lastdate = new Date(data[data.length - 1].date + 'Z');
      firstdate = new Date(data[0].date + 'Z');
    }
    if (events !== undefined) {
      for (let j = 0; j < events.data?.length; j = j + 1) {
        if (events.data[j].why !== 'Restart area') {
          fechaLocal = new Date(events.data?.[j].created_at + 'Z')
          pausedate.push(fechaLocal);
          pausewhy.push(events.data?.[j].why)
        }
      }
    }
  }

  if (current !== undefined) {
    const data1 = current.data?.[0]
    if (data1 !== undefined) {
      currenttemp = data1.temp;
      currenthumi = data1.humi;
      currentamper = data1.amper_ef.toFixed(2);
      currentenergy = data1.energy.toFixed(2);
      currentpower = data1.power.toFixed(2);
      const dateUTC = new Date(data1.date + 'Z')
      currentdate = dateUTC.toLocaleString(
        "en-CA",
        options
      );
      RH = currenthumi
      t1 = currenttemp
      H = (Math.log10(RH) - 2.0) / 0.4343 + (17.62 * t1) / (243.12 + t1);
      dewpoint = (243.12 * H / (17.62 - H)).toFixed(2);
    }
  }

  timeelapsed = timeConversion(Date.parse(lastdate) - Date.parse(firstdate))

  function timeConversion(millisec) {
    //var seconds = (millisec / 1000).toFixed(1);
    var minutes = (millisec / (1000 * 60.0)).toFixed(3);
    var hours = (millisec / (1000 * 60.0 * 60.0)).toFixed(3);
    var days = (millisec / (1000 * 60.0 * 60.0 * 24)).toFixed(3);

    let realdays = 0
    let realhours = 0
    let realminutes = 0
    if (days > 1) {
      realdays = days.toString().split('.')[0];
      hours = days.toString().split('.')[1] * 2.4 / 100
    }
    if (hours < 24) {
      realhours = hours.toString().split('.')[0];
      hours = parseFloat(hours).toFixed(3)
      minutes = hours.toString().split('.')[1] * 6.0 / 100
    }
    if (minutes < 60) {
      realminutes = minutes.toString().split('.')[0];
    }
    if (realdays < 1) {
      return realhours + " Hours " + realminutes + " Minutes"
    } else {
      return realdays + " Days " + realhours + " Hours " + realminutes + " Minutes"
    }
  }

  const pause_tracesh = []
  const humidity_traces = [
    {
      name: "Relative Humidity (%)",

      x: datedata,
      y: humidata,
      mode: "lines",
      line: { shape: "spline" },
      type: "scatter",
      smoothing: 1.3,
      marker: { color: "#54DED0" },
    },

    {
      name: "Relative Humidity Shadow",
      showlegend: false,
      x: datedata,
      y: humidata,
      mode: "lines",
      line: { shape: "spline", width: 10 },
      type: "scatter",
      smoothing: 1.3,
      marker: { color: "#54ded033" },
    },


    {
      name: "Temperature (°C)",
      x: datedata,
      y: tempdata,
      mode: "lines",
      line: { shape: "spline" },
      type: "scatter",
      smoothing: 1.3,
      marker: { color: "#ff76004f" },
    },
    {
      name: "Temperature Shadow(°C)",
      showlegend: false,
      x: datedata,
      y: tempdata,
      mode: "lines",
      line: { shape: "spline", width: 10 },
      type: "scatter",
      smoothing: 1.3,
      marker: { color: "#ff760014" },
    },
    {
      name: "Setpoint (%)",
      x: [firstdate, lastdate],
      y: [setpoint, setpoint],
      mode: "lines",
      type: "scatter",
      line: {
        dash: "dot",
        width: 1,
      },
      marker: { color: "#DDDDDD" },
    },
    {
      name: "Electrical Current (A)",
      x: datedata,
      y: amperdata,
      mode: "lines",
      line: { shape: "linear" },
      smoothing: 1.3,
      type: "scatter",
      marker: { color: "#F2D027" },
    },
    {
      name: "Electrical Current Shadow (A)",
      showlegend: false,
      x: datedata,
      y: amperdata,
      mode: "lines",
      line: { shape: "linear", width: 10 },
      smoothing: 1.3,
      type: "scatter",
      marker: { color: "#f2d02742" },
    }

  ]

  for (let i = 0; i < pausedate.length; i++) {
    pause_tracesh.push(
      {
        name: "Paused",
        x: [pausedate[i], pausedate[i]],
        y: [1, 80],
        mode: "lines+markers",
        line: {
          dash: "dot",
          width: 2,
        },
        type: "scatter",
        marker: { color: "black" },
        text: pausewhy[i],
        textposition: "middle right",
      }
    )
  }

  humidity_traces.push(...pause_tracesh)

  const whySchema = object().shape({
    why: string().max(250).required(),
  })
    
  const form = useForm({
    resolver: yupResolver(whySchema),
    values: {
      why: ''
    }
  })

  const onWhy = (data) => {
    changeStatusF("P", jobId, area.data?.id, currentenergy, timeelapsed, data.why)
  }

  if ( daily.isPending) {
    return <Loading />; 
  }

  return (
    <div className="container">
      <div className="font-bold text-2xl mb-2">{area.data?.aname} | {area.data?.name_raspi}</div>
      <div className="mb-2">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">{t('Claims')}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
            {arraySlash[2].length === 64 ?
              <BreadcrumbLink href={"/linktemp/" + arraySlash[2]}>{t('Areas')}</BreadcrumbLink>
              :
              <BreadcrumbLink href={"/lareas/" + jobId}>{t('Areas')}</BreadcrumbLink>
            }
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={window.location.href}>{t('Area')}</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>


      <Tabs defaultValue="monitoring">
        <TabsList>
          <TabsTrigger value="monitoring">{t('Monitoring')}</TabsTrigger>
          <TabsTrigger value="documents">{t('Documentation')}</TabsTrigger>
        </TabsList>
        { currentamper < amperage_expected - 1 && date_drying !== '1970-01-01 00:00:00' ?   <Alert variant="destructive" className="mt-4 flex max-h-screen w-full flex-col p-4 sm:bottom-0 sm:left-0 sm:top-auto sm:flex-col" >
        <AlertTitle>{t('LowAmperageWarning')}</AlertTitle>
        <AlertDescription>
          {/* {t('MessageLowAmperage'), {amperage_expected}, {currentamper}} */}

          <Trans i18nKey={"MessageLowAmperage"}>
            The real amperage is below the expected value for the type and amount of equipment in the area. please verify the equipment.
            we are expecting {{amperage_expected}} A and the current amperage is {{currentamper}} A
          </Trans>

        </AlertDescription>
      </Alert> : ''}
        <TabsContent value="monitoring">
          <div className="tab-container">
            <div className="plot-1">

              <Plot
                data={humidity_traces}
                useResizeHandler
                style={{ width: "100%", height: "100%" }}
                layout={{
                  margin:
                  {
                    l: 50,
                    r: 60,
                    b: 50,
                    t: 50,
                    pad: 4
                  },

                  yaxis: { range: [0, 100], color: '#9E9E9E' },
                  xaxis: {
                    autorange: true,
                    color: '#9E9E9E'
                  },
                  showlegend: true,
                  legend: {
                    bgcolor: 'rgba(0,0,0,0)',
                    itemclick: false,
                    itemdoubleclick: false,
                    x: 1,
                    xanchor: 'right',
                    y: 1,
                    font: {
                      size: 12,
                    }
                  },
                }}
              />
            </div>

            <div className="text-muted-foreground text-sm italic mb-2">
              {area.data?.description} 
            </div>
            <Telemetrics
              current_humidity={currenthumi}
              current_temperature={currenttemp}
              current_dewpoint={parseFloat(dewpoint)}
              current_amperage={parseFloat(currentamper)}
              current_power={parseFloat(currentpower)}
              current_energy={parseFloat(currentenergy)}
              timeelapsed={timeelapsed}
              last_date_updated={currentdate}
              airmovers={area.data?.blowers}
              dehumidifiers={area.data?.dh}
            />

            <div className="space-x-4">
              {user !== null && area.data?.status !== "P" && area.data?.status !== "F" ? (
                <>
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button>{t('Pause')}</Button>
                    </DialogTrigger>
                    <DialogContent>
                      <Form {...form}>
                        <form onSubmit={form.handleSubmit(onWhy)}>
                        <DialogHeader>
                          <DialogTitle>{t('Pause')}</DialogTitle>
                          <DialogDescription />
                        </DialogHeader>
                          <FormField
                            control={form.control}
                            name="why"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel />
                                <FormControl>
                                  <Input
                                    placeholder={t('Why')}
                                    {...field}
                                    {...form.register("why")}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        <DialogFooter>
                          <Button type='submit' disabled={form.formState.isSubmitSuccessful}>{t('Send')}</Button>
                        </DialogFooter>
                        </form>
                      </Form>
                    </DialogContent>
                  </Dialog>
                </>
              ) : ''}

              {user !== null && area.data?.status === "P" ?
                <Button onClick={() => changeStatusF("I", jobId, area.data?.id)}>{t('Unpause')}</Button>
                : ''
              }

              {user !== null && area.data?.status !== "F" ?
                <Button variant="finish" onClick={() => changeStatusF("F", jobId, area.data?.id, currentenergy, timeelapsed)}>{t('Finish')}</Button>
                : ''
              }

              {user !== null && area.data?.status === "F" ?
                <CSVLink data={daily.data} filename={"Job.csv"}>
                  <Button>{t('Download')}</Button>
                </CSVLink>
                : ''}
            </div>
          </div>

        </TabsContent>
        <TabsContent value="documents">
          {!loading ?
            <>
              <div className="claim-pictures">
                {areaPictures.length > 0 ? (
                  areaPictures.map((file) => (
                    <Viewbox
                      key={file.filename}
                      file={file}
                      jobid={jobId}
                      onDelete={handleDelete}
                    />
                  ))
                ) : (
                  <NoResults message={t('NoAreaPicture')} />
                )}
              </div>
              <div className="w-full text-center mt-2">
                <Button onClick={handleClick}>{t('AddAreaDocsPics')}</Button>
              </div>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </>
            :
            <div className="flex-div">
              <Spinner animation="border" variant="info" />
              <div>
                <h3>Loading file ...</h3>
              </div>
            </div>
          }
        </TabsContent>
      </Tabs>
      <div>
    </div>
    </div>
  )
}
