import React, { useCallback, useRef } from 'react';
import './Viewbox.scss';
import ip from '../../../config_ip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import wave from './Wave.png';
import { useAuth } from '@clerk/clerk-react';

const Viewbox = ({ file, jobid, onDelete }) => {

  const imageRef = useRef(null);
  const { getToken } = useAuth()

  const handleDownload = useCallback(() => {
    window.location.href = file.url;
  }, [file]);

  const handleDelete = useCallback(async () => {
    const tokenu = await getToken()
    try {
      const data = { filename: file.filename };
      await fetch(ip.url + '/deleteImage', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenu}`,
        },
      });
      onDelete(file);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  }, [file, onDelete, getToken]);

  const handleImageClick = useCallback(() => {
    if (imageRef.current) {
      const img = imageRef.current;
      if (img.requestFullscreen) {
        img.requestFullscreen();
      } else if (img.mozRequestFullScreen) { // Firefox
        img.mozRequestFullScreen();
      } else if (img.webkitRequestFullscreen) { // Chrome, Safari and Opera
        img.webkitRequestFullscreen();
      } else if (img.msRequestFullscreen) { // IE/Edge
        img.msRequestFullscreen();
      }
    }
  }, []);

  const fileExtension = file.filename.split('.').pop().toLowerCase();

  return (
    <div className="file-container">
      <div className="file-preview">
        {fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' ? (
          <>
            <img src={file.url} alt="Claim" className="claim-picture" ref={imageRef} />
            <FontAwesomeIcon icon={faExpand} color="#FFFFFF7a" className='resize' onClick={handleImageClick} />
          </>
        ) : fileExtension === 'pdf' ? (
          <>
            <embed src={`${file.url}#toolbar=0`} type="application/pdf" />
          </>
        ) : fileExtension === 'mp4' || fileExtension === 'webm' || fileExtension === 'ogg' ? (
          <>
            <video controls>
              <source src={file.url} type={`video/${fileExtension}`} />
              Your browser does not support the video tag.
            </video>
          </>
        ) : fileExtension === 'mp3' || fileExtension === 'wav' ? (
          <>
            <img src={wave} className="claim-picture audio" alt=""/>
            <audio controls>
              <source src={file.url} type={`audio/${fileExtension}`} />
              Your browser does not support the audio tag.
            </audio>
          </>
        ) : (
          <div>

            <FontAwesomeIcon icon={faFile} size="2x" />

          </div>
        )}
      </div>
      <div className='filename'>
        <p>{file.filename.split('/').pop()}</p>
      </div>
      <div className='file-button-container'>
        <FontAwesomeIcon icon={faDownload}  onClick={handleDownload} color="#FFFFFF22" className='action-button'/> 
        <FontAwesomeIcon icon={faTrash}  onClick={handleDelete} color="#FFFFFF22" className='action-button'/> 
      </div>
    </div>
  );
};

export default Viewbox;

