import React from "react";
import { TableRow, TableCell } from "@/components/ui/table";
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem
} from "@/components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";

export default function Contractor(props) {

  const { contractor } = props;

  return (
    <TableRow align="left">
      <TableCell className="p-1">{contractor.id}</TableCell>
      <TableCell className="p-1">{contractor.name}</TableCell>
      <TableCell className="p-1">{contractor.address}</TableCell>
      <TableCell className="p-1">{contractor.email}</TableCell>
      <TableCell className="p-1">{contractor.phone}</TableCell>
      <TableCell className="p-1">{contractor.contact}</TableCell>
      <TableCell className="p-1">{contractor.status}</TableCell>
      <TableCell className="p-1">{contractor.logo}</TableCell>
      <TableCell className="p-1">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0 ">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => window.location.href="/econtractor/" + contractor.id}>Edit Contractor</DropdownMenuItem>
            <DropdownMenuItem onClick={() => window.location.href="/addcontractor"}>Create Contractor</DropdownMenuItem>
            <DropdownMenuItem onClick={() => window.location.href="/assigninsu/" + contractor.id + "/" + contractor.name}>Assign Insurance</DropdownMenuItem>
            <DropdownMenuItem onClick={() => window.location.href="/assignrobbie/" + contractor.id + "/" + contractor.name}>Assign Robbies</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  )
}
