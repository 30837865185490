import React from "react";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormLabel,
  FormControl,
  FormItem 
} from '@/components/ui/form.jsx';
import { Input } from "@/components/ui/input.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { FaSave } from "react-icons/fa";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ip from "../../config_ip";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

const contractor_id = localStorage.getItem('temp')

export default function RobbieForm () {

  const { getToken } = useAuth()

  const mutation = useMutation({
    mutationFn: async (data) => {
      const tokenu = await getToken()
      const response = await fetch(ip.url + '/addRobbie', {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )

  const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = '/lRobbies'
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
  };

  const robbieFormSchema = object().shape({
    name_raspi: string()
    .max(8)
    .min(8),
  sensor: string(),
  })
  .required()

  const form = useForm({
    resolver: yupResolver(robbieFormSchema),
    values: {
      name_raspi: '',
      sensor: '',
      status: 'A',
      moisture: 'N',
      usemoisture: 'N',
    },
  })

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/lRobbies"}>List Robbies</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Create Robbie</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="name_raspi"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Robbie Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Robbie Name RXXXXXXX"
                    {...field}
                    {...form.register("name_raspi")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="sensor"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Sensor Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Sensor Name"
                    {...field}
                    {...form.register("sensor")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Status</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder='Select Robbie Id' />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="A">Active</SelectItem>
                        <SelectItem value="I">Inactive</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField control={form.control}
            name="moisture"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Has Moisture Sensor</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder='Select Aswer' />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="Y">Yes</SelectItem>
                        <SelectItem value="N">No</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField control={form.control}
            name="usemoisture"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Use Moisture</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder='Select Aswer' />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="Y">Yes</SelectItem>
                        <SelectItem value="N">No</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <Button type="submit">
            <FaSave></FaSave>
          </Button>
        </form>
      </Form>
    </div>
  )
}


