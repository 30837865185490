import React from "react";
import ip from "../../config_ip"
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button.jsx";
import { Input } from "@/components/ui/input.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import {
  Form,
  FormField,
  FormLabel,
  FormItem,
  FormControl
} from "@/components/ui/form.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import { FaSave } from "react-icons/fa";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from '@clerk/clerk-react';
import { useMutation } from "@tanstack/react-query";

import { Loading } from "../UI/Loading";

const adjuster_id = window.location.pathname.split('/')[2];
const contractor_id = localStorage.getItem('temp')

export default function Editadjuster (props) {

  const { getToken } = useAuth()

  const mutation = useMutation({
    mutationFn: async (data) => {
      const tokenu = await getToken()
      const response = await fetch(ip.url + '/updateAdjuster/' + adjuster_id, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )

  const contractors = useAllFetch(['contractors'], '/contractors')
  const insurances = useAllFetch(['insurances'], '/insurances')
  const getAdjuster = useAllFetch(['getAdjuster'], '/getAdjuster/' + adjuster_id)
      
  const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = '/ladjuster'
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
  }

  const editAdjusterSchema = object().shape({
    id: number(),
    aname: string(),
    aphone: string()
      .min(7, "Too Short!")
      .max(11, "Too Long!"),
    username: string()
      .min(3, "Too Short!")
      .max(50, "Too Long!"),
    insurco_id: number(),
    password: string(),
    contractor_id: number(),
    role: string(),
  })

  const form = useForm({
    resolver: yupResolver(editAdjusterSchema),
    values: {
      id: getAdjuster.data?.id,
      aname: getAdjuster.data?.aname,
      aphone: getAdjuster.data?.aphone,
      username: getAdjuster.data?.username,
      insurco_id: getAdjuster.data?.insurco_id,
      password: getAdjuster.data?.password,
      contractor_id: getAdjuster.data?.contractor_id,
      role: getAdjuster.data?.role,
    }
  })

  if (contractors.isPendig || insurances.isPending || getAdjuster.isPending) {
    return <Loading />;  }

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={"/ladjuster"}>List Adjusters</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Edit Adjuster</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="aname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Adjuster Name</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter Adjuster name"
                  {...field}
                  {...form.register("aname")}
                  value={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="aphone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Adjuster Phone</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter Adjuster phone"
                  {...field}
                  {...form.register("aphone")}
                  value={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          type="email"
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Adjuster User Name</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your Email"
                  {...field}
                  {...form.register("username")}
                  value={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField control={form.control}
          name="insurco_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Insurance Companies</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  value={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder='Select Insurance Name' />
                  </SelectTrigger>
                  <SelectContent>
                    {insurances.data?.map((insurance) => (
                      <SelectItem
                        key={insurance.id}
                        value={(insurance.id).toString()}
                      >
                        {insurance.insurancename}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          type="password"
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter Adjuster Password"
                  {...field}
                  {...form.register("password")}
                  value={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField control={form.control}
          name="contractor_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contractor Name</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  value={field.value}    //Tengo problemas mostrando el valor que trae de la base
                >
                  <SelectTrigger>
                    <SelectValue placeholder='Select Contractor Name' />
                  </SelectTrigger>
                  <SelectContent>
                    {contractors.data.map((contractor) => (
                      <SelectItem
                        key={contractor.id}
                        value={(contractor.id).toString()}
                      >
                        {contractor.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  value={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder='Select a role' />
                  </SelectTrigger>
                  <SelectContent>
                      <SelectItem value="manager">Manager</SelectItem>
                      <SelectItem value="member">Member</SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
          <Button type="submit">
            <FaSave></FaSave>
          </Button>
        </form>
      </Form>
    </div>
  )
}