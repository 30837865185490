import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button.jsx";
import {
  Form,
  FormField,
  FormLabel,
  FormControl,
  FormItem,
} from "@/components/ui/form.jsx"
import {
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@/components/ui/table.jsx";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb.jsx";
import { object, number} from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaSave } from "react-icons/fa";
import ip from "../../config_ip";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react"

import { Loading } from "../UI/Loading";

const contractor_id = window.location.pathname.split('/')[2]
const contractorName = window.location.pathname.split('/')[3]

export default function Assigninsu () {
  
  const namesearch = contractorName.replaceAll("%20", " ")
  const [insurco_id, setInsurco_id] = useState(0)

  const { getToken } = useAuth()

  const insucont = useAllFetch(['insucont'], '/insucont/' + contractor_id)
  const assigninsu = useAllFetch(['assigninsu'], '/assigninsucont/' + contractor_id)

  const onSubmit = async (data) => {
    const tokenu = await getToken()
    await fetch(ip.url + "/assigninsu", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenu}`
      },
    })
    window.location.pathname = "/assigninsu/" + contractor_id + "/" + contractorName;
  }

  const assigninsuSchema = object().shape({
    insurco_id: number()
  })

  const form = useForm({
    resolver: yupResolver(assigninsuSchema),
    values: {
      insurco_id: insurco_id,
      contractor_id,
    }
  })

  if (insucont.isLoading || assigninsu.isLoading) {
    return <Loading />;  }

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/lcontractors"}>List Contractors</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Assing Insurances</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <p>Contractor name: {namesearch}</p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="insurco_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Insurances </FormLabel>
                <FormControl>
                  <Select
                    onValueChange={value => {setInsurco_id(value)}}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder='Select a name insurance'/>
                    </SelectTrigger>
                    <SelectContent>
                      {insucont.data.map(insurance => (
                        <SelectItem
                          key={insurance.id}
                          value={insurance.id.toString()}
                        >
                          {insurance.insurancename}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <div>
            <Button type="submit">
              <FaSave></FaSave>
            </Button>
          </div>
        </form>
      </Form>
      <div align='center'>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>
                Assigned Insurances
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {assigninsu.data.length > 0 ? assigninsu.data.map(insurance => (
                <TableRow align="left" key={insurance.id}>
                  <TableCell className="p-1">
                    {insurance.insurancename}
                  </TableCell>
                </TableRow>
              ))
            : <TableRow align="left">
                <TableCell>
                  There is not assign insurances
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
