import React from 'react'
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
 } from '@/components/ui/table.jsx';
 import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import Param from './Param'
import { useAllFetch } from '../CustomHooks/useAllFetch'

import { Loading } from "../UI/Loading";

const contractor_id = localStorage.getItem('temp')

export default function Params() {

  const params = useAllFetch(['params'], '/lParams')
  
  if (params.isPending) {
    return <Loading />;  }

  return (
    <div className='container'>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>List Parameters</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="title-2 mt-2 ml-3">
        Parameters
      </div>
      <Table responsive="true" striped="true" size="sm" className="mt-2 mr-2 ml-3" style={{width: '98%'}}>
        <TableHeader align="left">
          <TableRow>
            <TableHead></TableHead>
            <TableHead>HST</TableHead>
            <TableHead>HAI</TableHead>
            <TableHead>YAER</TableHead>
            <TableHead>MONTH</TableHead>
            <TableHead>DAY</TableHead>
            <TableHead>OFFPEAK</TableHead>
            <TableHead>MIDPEAK</TableHead>
            <TableHead>ONPEAK</TableHead>
            <TableHead>ACTIONS</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          { params.data.map(param => <Param param={param} key={param.id} /> ) }
        </TableBody>            
      </Table>
    </div>
  )
}
