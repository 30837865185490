import React from 'react';
import { SignIn } from "@clerk/clerk-react"
import "./styles.scss";

const SignInPageNew = () => {
    return (
        <div className='absolute top-0 left-0 w-full h-screen -z-10'>
            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10'>
            <SignIn />
            </div>
        </div>
    );
};

export default SignInPageNew;