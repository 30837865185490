import React from "react";

export const NoResults = ({ message }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center text-muted-foreground -z-10">
      {message}
    </div>
  );
};

export default NoResults;
