import React, { useState, useEffect } from "react";
import ip from "../../config_ip";
import QRCode from 'qrcode.react';
import "./styles.scss";
import { Button } from '@/components/ui/button';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useAuth } from "@clerk/clerk-react";

const linkj = window.location.pathname.split('/')[3]
const contractorId = window.location.pathname.split('/')[2]
const contractor_id = localStorage.getItem('contractor_id')

export default function Genpub () {

  const [data1, setData1] = useState([])
  const [linkJob, setLinkJob] = useState('')
  const { getToken } = useAuth()
  
  useEffect(() => {
    async function genlink () {
      const data = {
        link: linkj,          // claim id
        contractor: contractorId     // contractor id
      }
      const tokenu = getToken()
      const resp = await fetch(ip.url + "/genlink", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Berear ${tokenu}`
        },
      });
      setData1(await resp.json())
    }
    genlink()
   }, [getToken])
 
  useEffect(() => {
    setLinkJob(data1.data?.linkjob)
  }, [data1])

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Scan QR</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="qr-container">
        <div className="title">
          <p>Scan this code to get access to your mitigation progress</p>
        </div>
        <QRCode
          value={"https://app.robbiert.com/authorization/" + String(linkJob)} size={300} fgColor='#17aee5' className="qr-code">
        </QRCode>
        <div className="button-container">
          <Button onClick={() => navigator.clipboard.writeText("https://app.robbiert.com/authorization/" + String(linkJob))}>
            Copy to clipboard
          </Button>
        </div>
      </div>
      <div className="link">
        <small> https://app.robbiert.com/authorization/{linkJob} </small>
        </div>
    </div>
  )
}

