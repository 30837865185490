import React from 'react';
import { createRoot } from 'react-dom/client'
import './index.scss';
import App from './App';
import * as Sentry from "@sentry/react";
import * as serviceWorker from './serviceWorker';
import NavBar from './components/Navbar';
import config_ip from './config_ip.js';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { ClerkProvider } from '@clerk/clerk-react'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import './components/Utils/i18n'

// get the path of the current page

const PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const queryClient = new QueryClient()

Sentry.init({
  dsn: config_ip.sentry_dns,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container); // Create a root

// Use the render method on root
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
        {window.location.pathname.includes('/certificate2/') || window.location.pathname.includes('/public_map') ? '' : <NavBar />}
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </ClerkProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
