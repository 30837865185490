import styled from '@emotion/styled';
import { css } from '@emotion/react';

const active = css`
`;

const disabled = css`
  cursor: not-allowed;
  pointer-events: none;
`;

const StatusLightStylesType = {
  active,
  disabled,
};

export const RobbieButtonStyled = styled.a`
  ${(props) => StatusLightStylesType[props.type]};
  width: 40px;
  height: 40px;
  color: black;
  border: none;
  background-color: white;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  
  img {
    width: 25px;
    height: 25px;
    fill: #212529;

  }
`;
