import React, { useState, useEffect } from "react";
import Areap from "../AreasPub/Areap";
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Allfetch from "../Allfetch";

const para = window.location.pathname.split('/')    // para[2] es el enlace del trabajo

export default function Viewpub () {

  const [link, setLink] = useState([])
  const [areas, setAreas] = useState([])
  const [times, setTimes] = useState([])

  useEffect(() => {
    Allfetch('/getlink/' + para[2], setLink)
  }, [])

  useEffect(() => {
    Allfetch('/linkareas/' + link?.id, setAreas)
    Allfetch('/timelapsedPub/' + link?.id, setTimes)
  }, [link])

  if (areas !== undefined) {
    return (
      <Container>
        <Table responsive size="lg" borderless style={{ width: '100%' }}>
          <thead align="left">
            <tr>
              <th>NAME</th>
              <th>STATUS</th>
              <th>TIME BREAKDOWN</th>
              <th>DRYING SETUP</th>
              <th>ROBBIE ID</th>
              <th className='action-header'>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            { areas.map(area => <Areap area={area} key={area.id} times={times} link1={para[2]} link2={area.link} />) }
          </tbody>
        </Table>
      </Container> 
    )
  }
}
