import React from "react";
import {
  TableRow,
  TableCell }
from "@/components/ui/table.jsx";
import { 
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu.jsx";
import { MoreHorizontal } from "lucide-react";
import { Button } from "@/components/ui/button.jsx";

export default function Adjuster (props) {
  
  const { adjuster } = props;
  
  return (
    <TableRow align="left">
      <TableCell className="p-0">{adjuster.id}</TableCell>
      <TableCell className="p-0">{adjuster.aname}</TableCell>
      <TableCell className="p-0">{adjuster.aphone}</TableCell>
      <TableCell className="p-0">{adjuster.username}</TableCell>
      <TableCell className="p-0">{adjuster.insurancename}</TableCell>
      <TableCell className="p-0">{adjuster.role}</TableCell>
      <TableCell className="p-0">{adjuster.insurancename}</TableCell>
      <TableCell className="p-0">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0 ">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => window.location.href="/eadjuster/" + adjuster.id}>Edit Adjuster</DropdownMenuItem>
            <DropdownMenuItem onClick={() => window.location.href="/addAdjuster"}>Create Adjuster</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
}
