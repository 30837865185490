import React from 'react';
import { Button } from '@/components/ui/button.jsx';
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@/components/ui/table.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { useAllFetch } from '../CustomHooks/useAllFetch';

import { Loading } from "../UI/Loading";

let totdh = 0
let totbl = 0
let totelaptime = 0
let tottimdry = 0
let totcosequ = 0
let totcosare = 0
let tothousav = 0
let cos_equ_op = 0
let cos_tot_equ = 0
// let cos_tot_areas = 0
let totestdh = 0
let totestdhop = 0
let totestdhhst = 0
let totestbl = 0
let totestblop = 0
let totestblhst = 0
let totestequ = 0
let totestequop = 0
let totestequhst = 0
let totenergy = 0
let totcosenergy = 0
let totenesaving = 0
let totcossaving = 0
let chargeallowance = 0
let totsavCAD = 0

const job_id = window.location.pathname.split('/')[2]
const contractor_id = localStorage.getItem('contractor_id')

export default function Costs() {

  const costs = useAllFetch(['getcosts'], '/getCosts/' + job_id)

  if (costs.isPending) {
    return <Loading />;
  }

  const calc_totals = () => {
    totdh = 0
    totbl = 0
    totelaptime = 0
    tottimdry = 0
    totcosequ = 0
    totcosare = 0
    tothousav = 0
    cos_tot_equ = 0
//    cos_tot_areas = 0
    totestdh = 0
    cos_equ_op = 0
    totestdhop = 0
    totestdhhst = 0
    totestbl = 0
    totestblop = 0
    totestblhst = 0
    totestequ = 0
    totestequop = 0
    totestequhst = 0
    totenergy = 0
    totcosenergy = 0
    totenesaving = 0.0
    totcossaving = 0.0
    totsavCAD = 0.0
    costs.data?.map(cost => ((
      // Calculo de Totales de generales en el trabajo
      totdh = totdh + cost.dh,                                                                // Calcula total de dehumidificadores
      totbl = totbl + cost.bl,                                                                // Calcula total de Blowers
      totelaptime = totelaptime + parseFloat(cost.elap_time),                                             // Calcula tiempo total transcurrido
      tottimdry = tottimdry + cost.hours_drying,                                              // Calcula tiempo total de secado
      totcosequ = totcosequ + (cost.cos_tot_bl + cost.cos_tot_dh),                            // Calcula costo total de los equipos rentados dh y blowers
      chargeallowance = totcosequ * 72,                                                       // Calcula costo toal permitido (Costo toatl equipos * 72)
//      cos_tot_areas += cost.cos_tot_are,                                                      // Calcula el costo total de las areas 
      totcosare = totcosare + (chargeallowance <= cost.cos_tot_are ? 0.0 : cost.cos_tot_are), // Calcula el costo total por area
      tothousav = tothousav + (cost.hours_saving >= 0 ? cost.hours_saving : 0),               // Calcula el total de horas ahorradas
      totenergy = totenergy + cost.kwh,                                                       // Calcula la cantidad de energia 
      totcosenergy = totcosenergy + cost.cos_tot_ene,                                         // Calcula el costo total de energia por area
      totenesaving = totenesaving + (cost.kwh_saving > 0 ? cost.kwh_saving : 0.0),            // Calcula el total de energia ahorrada por area
      totcossaving = totcossaving + (cost.cos_ene_sav > 0 ? cost.cos_ene_sav : 0.0),          // Calcula el costo total de energia ahorrada por areas
      totsavCAD = totsavCAD + (cost.hours_saving > 0 ? cost.hours_saving * (cost.cos_tot_bl + cost.cos_tot_dh) : 0.0), // calcula el costo total de ahorro en cad
      // Calculo de Totales de costos estimados
      totestdh = totestdh + (cost.cos_tot_dh * cost.hours_drying),
      totestdhop = totestdhop + (cost.cos_tot_dh * cost.hours_drying) * 1.15,
      totestdhhst = totestdhhst + (cost.cos_tot_dh * cost.hours_drying) * 1.15 * (1 + parseFloat(costs.data?.[0].taxrate/100)),
      totestbl = totestbl + (cost.cos_tot_bl * cost.hours_drying),
      totestblop = totestblop + (cost.cos_tot_bl * cost.hours_drying) * 1.15,
      totestblhst = totestblhst + (cost.cos_tot_bl * cost.hours_drying) * 1.15 * (1 + parseFloat(costs.data?.[0].taxrate/100)),
      totestequ = totestequ + (cost.cos_tot_bl + cost.cos_tot_dh) * cost.hours_drying,
      totestequop = totestequop + (cost.cos_tot_bl + cost.cos_tot_dh) * cost.hours_drying * 1.15,
      totestequhst = totestequhst + (cost.cos_tot_bl + cost.cos_tot_dh) * cost.hours_drying * 1.15 * (1 + parseFloat(costs.data?.[0].taxrate/100))
    )))
    cos_equ_op = totcosare * 0.15
    cos_tot_equ = totcosare + cos_equ_op
  }

  calc_totals()

  return (
    <div className='container'>
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink href={window.location.href}>Cost</BreadcrumbLink>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
    {/* <Buttonback  url={"/jobs/" + contractor_id}/> */}
    <div align="center" className="mt-2 mb-4">Costs
      <Table className="mt-1">
        <TableHeader align="left" style={{backgroundColor: 'lightgray'}}>
          <TableRow>
            <TableCell className="p-1" >Claim Number</TableCell>
            <TableCell className="p-1" >Address</TableCell>
            <TableCell className="p-1" >Certificate</TableCell>
            <TableCell className="p-1" >Claim id</TableCell>
            <TableCell className="p-1" >Adjuster</TableCell>
            <TableCell className="p-1" >Email</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow align="left">
            <TableCell className="p-1">{costs.data?.[0].claimnumber}</TableCell>
            <TableCell className="p-1">{costs.data?.[0].address}</TableCell>
            <TableCell className="p-1" align="center">{costs.data?.[0].certificate}</TableCell>
            <TableCell className="p-1" align="center">{costs.data?.[0].job_id}</TableCell>
            <TableCell className="p-1">{costs.data?.[0].name}</TableCell>
            <TableCell className="p-1">{costs.data?.[0].email_adj}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table className="mt-1">
        <TableHeader style={{backgroundColor: 'lightgray'}}>
          <TableRow>
            <TableCell className="p-1">Id area</TableCell>
            <TableCell className="p-1">Name area</TableCell>
            <TableCell className="p-1">DH</TableCell>
            <TableCell className="p-1">BL</TableCell>
            <TableCell className="p-1">Elapsed Time</TableCell>
            <TableCell className="p-1">T_drying</TableCell>
            <TableCell className="p-1">Cost Equ.</TableCell>
            <TableCell className="p-1">Cost Tot Area.</TableCell>
            <TableCell className="p-1">H. Saving</TableCell>
            <TableCell className="p-1">CAD Saving</TableCell>
            <TableCell className="p-1" style={{color: 'green'}}>Energy (KWh)</TableCell>
            <TableCell className="p-1" style={{color: 'green'}}>Cost Ene CAD</TableCell>
            <TableCell className="p-1" style={{color: 'green'}}>Ene. Sav. (KWh)</TableCell>
            <TableCell className="p-1" style={{color: 'green'}}>Cost Sav CAD</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {costs.data?.map(cost => (
            <TableRow key={cost.id} align="left">
              <TableCell className="p-1">{cost.id}</TableCell>
              <TableCell className="p-1">{cost.aname}</TableCell>
              <TableCell className="p-1" align="center">{cost.dh}</TableCell>
              <TableCell className="p-1" align="center">{cost.bl}</TableCell>
              {cost.elap_time ? <TableCell className="p-1" align="right">{parseFloat(cost.elap_time).toFixed(2)} H</TableCell> : <TableCell className="p-1" align="right">0.0</TableCell>}
              <TableCell className="p-1" align="right">{parseFloat(cost.hours_drying).toFixed(2)} H</TableCell>
              <TableCell className="p-1" align="right">$ {parseFloat(cost.cos_tot_bl + cost.cos_tot_dh).toFixed(2)}</TableCell>
              {cost.cos_tot_are ? <TableCell className="p-1" align="right">$ {chargeallowance < cost.cos_tot_are ? 0.0 : parseFloat(cost.cos_tot_are).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right">0.0</TableCell>}
              {cost.hours_saving > 0 ? <TableCell className="p-1" align="right">{parseFloat(cost.hours_saving).toFixed(2)} H</TableCell> : <TableCell className="p-1" align="right">0.0</TableCell>}
              {(parseFloat(cost.cos_tot_bl + cost.cos_tot_dh) * parseFloat(cost.hours_saving)) < 0 ? <TableCell className="p-1" align="right">0.0</TableCell> : <TableCell className="p-1" align="right" >$ {(parseFloat(cost.cos_tot_bl + cost.cos_tot_dh) * parseFloat(cost.hours_saving)).toFixed(2)}</TableCell>}
              <TableCell className="p-1" align="right" style={{backgroundColor: '#00FFAA'}}>{parseFloat(cost.kwh).toFixed(2)}</TableCell>
              {cost.cos_tot_ene > 0 ? <TableCell className="p-1" align="right" style={{backgroundColor: '#00FFAA'}}>$ {parseFloat(cost.cos_tot_ene).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right" style={{backgroundColor: '#00FFAA'}}>0.0</TableCell>}
              {cost.kwh_saving > 0 ? <TableCell className="p-1" align="right" style={{backgroundColor: '#00FFAA'}}>{parseFloat(cost.kwh_saving).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right" style={{backgroundColor: '#00FFAA'}}>0.0</TableCell>}
              {cost.cos_ene_sav > 0 ? <TableCell className="p-1" align="right" style={{backgroundColor: '#00FFAA'}}>$ {parseFloat(cost.cos_ene_sav).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right" style={{backgroundColor: '#00FFAA'}}>0.0</TableCell>}
            </TableRow>
          ))}
          <TableRow style={{color: 'blue'}}>
            <TableCell className="p-1"></TableCell>
            <TableCell className="p-1">Totals</TableCell>
            <TableCell className="p-1" align="center">{totdh}</TableCell>
            <TableCell className="p-1" align="center">{totbl}</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(totelaptime).toFixed(2)} H</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(tottimdry).toFixed(2)} H</TableCell>
            <TableCell className="p-1" align="right">$ {parseFloat(totcosequ).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">$ {parseFloat(totcosare).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(tothousav).toFixed(2)} H</TableCell>
            <TableCell className="p-1" align="right">$ {parseFloat(totsavCAD).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right" style={{backgroundColor: '#00FFAA'}}>{totenergy ? parseFloat(totenergy).toFixed(2) : 0.0}</TableCell>
            <TableCell className="p-1" align="right" style={{backgroundColor: '#00FFAA'}}>$ {totcosenergy ? parseFloat(totcosenergy).toFixed(2) : 0.0}</TableCell>
            <TableCell className="p-1" align="right" style={{backgroundColor: '#00FFAA'}}>{parseFloat(totenesaving).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right" style={{backgroundColor: '#00FFAA'}}>$ {parseFloat(totcossaving).toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table className="mt-1">
        <TableBody>
          <TableRow align="center">
            <TableCell className="p-1" align="right">Avg. Elapsed Time:</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(totelaptime/costs.data?.length).toFixed(2)} H</TableCell>
            <TableCell className="p-1" align="right">Avg. Drying Time:</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(tottimdry/costs.data?.length).toFixed(2)} H</TableCell>
            <TableCell className="p-1" align="right"></TableCell>
            <TableCell className="p-1" align="right"></TableCell>
          </TableRow>
          <TableRow align="left">
            <TableCell className="p-1" align="right">Charges Allowance:</TableCell>
            <TableCell className="p-1" align="right">$ {parseFloat(chargeallowance).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">Sub Tot Cost Equ:</TableCell>
            <TableCell className="p-1" align="right">$ {parseFloat(totcosare).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">Saving of Equip.:</TableCell>
            {totsavCAD > 0 ? <TableCell className="p-1" align="right">$ {parseFloat(totsavCAD).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right">0.0</TableCell>}
          </TableRow>
          <TableRow align="left">
            <TableCell className="p-1" align="right">OP on C. Allowance:</TableCell>
            <TableCell className="p-1" align="right">$ {parseFloat(chargeallowance*0.15).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">Cost Equ OP:</TableCell>
            <TableCell className="p-1" align="right">$ {parseFloat(cos_equ_op).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">Saving on OP:</TableCell>
            {totsavCAD > 0 ? <TableCell className="p-1" align="right">$ {parseFloat(totsavCAD*0.15).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right">$ 0.0</TableCell>} 
          </TableRow>
          <TableRow align="left">
            <TableCell className="p-1" align="right">Total C. Allowance:</TableCell>
            <TableCell className="p-1" align="right">$ {parseFloat(chargeallowance*1.15).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">Tot Cost Equ:</TableCell>
            <TableCell className="p-1" align="right">$ {parseFloat(cos_tot_equ).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">Total Savings:</TableCell>
            {totsavCAD > 0 ? <TableCell className="p-1" align="right">$ {parseFloat(totsavCAD*1.15).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right">$ 0.0</TableCell>}
          </TableRow>
          <TableRow align="left">
            <TableCell colSpan="4" className="p-1"></TableCell>
            <TableCell className="p-1" align="right">% Savings:</TableCell>
            {totsavCAD > 0 ? <TableCell className="p-1" align="right">{parseFloat([(totsavCAD*1.15)/(chargeallowance*1.15)]*100).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right">0.00</TableCell>}
          </TableRow>
          <TableRow align="left">
            <TableCell className="p-1" align="right">Insurance Savings:</TableCell>
            {totsavCAD > 0 ? <TableCell className="p-1" align="right">$ {parseFloat(((totsavCAD)*1.15)*0.7).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right">$ 0.0</TableCell>}
            <TableCell className="p-1" align="right" style={{color: 'red'}}>Robbie Savings:</TableCell>
            {totsavCAD > 0 ? <TableCell className="p-1" align="right" style={{color: 'red'}}>$ {parseFloat(((totsavCAD)*1.15)*0.3).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right">$ 0.0</TableCell>}
          </TableRow>
          <TableRow align="left">
            {/* <TableCell className="p-1" align="right">Insurance's Revenue:</TableCell>
            <TableCell className="p-1" align="right">$ {(chargeallowance <= cos_tot_areas) ? 0 : parseFloat((chargeallowance*1.15 - cos_tot_equ) - ((chargeallowance*1.15 - cos_tot_equ)*0.3 + costs.data?.length*75)).toFixed(2)}</TableCell> */}
            <TableCell className="p-1" align="right"></TableCell>
            <TableCell className="p-1" align="right"></TableCell>
            <TableCell className="p-1" align="right" style={{color: 'red'}}>R S Rental Fee:</TableCell>
            <TableCell className="p-1" align="right" style={{color: 'red'}}>$ {parseFloat(costs.data?.length*75).toFixed(2)}</TableCell>
          </TableRow>
          <TableRow align="left">
            {/* <TableCell className="p-1" align="right">% Insurance Savings:</TableCell>
            <TableCell className="p-1" align="right">{(chargeallowance*1.15 <= cos_tot_areas) ? 0 : parseFloat((((chargeallowance*1.15 - cos_tot_equ) - ((chargeallowance*1.15 - cos_tot_equ)*0.3 + costs.data?.length*75))/(chargeallowance*1.15 - cos_tot_equ))*100).toFixed(2)}</TableCell> */}
            <TableCell className="p-1" align="right"></TableCell>
            <TableCell className="p-1" align="right"></TableCell>
            <TableCell className="p-1" align="right" style={{color: 'red'}}>Robbie Invoice:</TableCell>
            {totsavCAD > 0 ? <TableCell className="p-1" align="right" style={{color: 'red'}}>$ {parseFloat(((totsavCAD)*1.15)*0.3 + costs.data?.length*75).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right">$ {parseFloat(costs.data?.length*75).toFixed(2)}</TableCell>}
            <TableCell className="p-1" align="right" style={{color: 'red'}}>Robbie Invoice HST:</TableCell>
            {totsavCAD > 0 ? <TableCell className="p-1" align="right" style={{color: 'red'}}>$ {parseFloat((((totsavCAD)*1.15)*0.3 + (costs.data?.length*75)) * (costs.data?.[0].taxrate/100)).toFixed(2)}</TableCell> : <TableCell className="p-1" align="right">$ {parseFloat(((costs.data?.length*75)) * (parseFloat(costs.data?.[0].taxrate/100))).toFixed(2)}</TableCell>}
          </TableRow>
        </TableBody>
      </Table>

      <Table className="mt-1">
        <TableHeader style={{backgroundColor: 'lightgray'}}>
          <TableRow>
            <TableHead colSpan="2"></TableHead>
            <TableHead colSpan="3" align="center">Costs Estimated DH</TableHead>
            <TableHead colSpan="3" align="center">Costs Estimated BL</TableHead>
            <TableHead colSpan="3" align="center">Costs Estimated Equipment</TableHead>
          </TableRow>
          <TableRow>
            <TableCell className="p-1">Id area</TableCell>
            <TableCell className="p-1">Name area</TableCell>
            <TableCell className="p-1" align="center">DH</TableCell>
            <TableCell className="p-1" align="center">DH OP</TableCell>
            <TableCell className="p-1" align="center">DH HST</TableCell>
            <TableCell className="p-1" align="center">BL</TableCell>
            <TableCell className="p-1" align="center">BL OP</TableCell>
            <TableCell className="p-1" align="center">BL HST</TableCell>
            <TableCell className="p-1" align="center">EQU</TableCell>
            <TableCell className="p-1" align="center">EQU OP</TableCell>
            <TableCell className="p-1" align="center">EQU HST</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {costs.data?.map(cost => (
            <TableRow key={cost.id} align="left">
              <TableCell className="p-1">{cost.id}</TableCell>
              <TableCell className="p-1">{cost.aname}</TableCell>
              <TableCell className="p-1" align="right">{parseFloat(cost.cos_tot_dh * cost.hours_drying).toFixed(2)}</TableCell>
              <TableCell className="p-1" align="right">{parseFloat(cost.cos_tot_dh * cost.hours_drying * 1.15).toFixed(2)}</TableCell>
              <TableCell className="p-1" align="right">{parseFloat(cost.cos_tot_dh * cost.hours_drying * 1.15 * (1 + parseFloat(costs.data?.[0].taxrate/100))).toFixed(2)}</TableCell>
              <TableCell className="p-1" align="right">{parseFloat(cost.cos_tot_bl * cost.hours_drying).toFixed(2)}</TableCell>
              <TableCell className="p-1" align="right">{parseFloat(cost.cos_tot_bl * cost.hours_drying * 1.15).toFixed(2)}</TableCell>
              <TableCell className="p-1" align="right">{parseFloat(cost.cos_tot_bl * cost.hours_drying * 1.15 * (1 + parseFloat(costs.data?.[0].taxrate/100))).toFixed(2)}</TableCell>
              <TableCell className="p-1" align="right">{(parseFloat(cost.cos_tot_dh + cost.cos_tot_bl) * cost.hours_drying).toFixed(2)}</TableCell>
              <TableCell className="p-1" align="right">{parseFloat((cost.cos_tot_dh + cost.cos_tot_bl) * cost.hours_drying * 1.15).toFixed(2)}</TableCell>
              <TableCell className="p-1" align="right">{parseFloat((cost.cos_tot_dh + cost.cos_tot_bl) * cost.hours_drying * 1.15 * (1 + parseFloat(costs.data?.[0].taxrate/100))).toFixed(2)}</TableCell>
            </TableRow>
          ))}
          <TableRow style={{color: 'blue'}}>
            <TableCell className="p-1"></TableCell>
            <TableCell className="p-1">Totals</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(totestdh).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(totestdhop).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(totestdhhst).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(totestbl).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(totestblop).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(totestblhst).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(totestequ).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(totestequop).toFixed(2)}</TableCell>
            <TableCell className="p-1" align="right">{parseFloat(totestequhst).toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {costs.data?.[0].DocNumber ?
        <Button className='mt-3' variant="destructive" disabled={true}>Claim already invoiced: {costs.data?.[0].DocNumber}</Button>
      :
        <Button className='mt-3' onClick={() => window.location.href="/invoice/" + job_id}>Invoice Draft</Button>
      }
    </div>
    </div>
  )
}
