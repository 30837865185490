import React from 'react';
import Contractor from './Contractor';
import { Table } from '@/components/ui/table.jsx';
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb";
import { useAllFetch } from '../CustomHooks/useAllFetch';

import { Loading } from "../UI/Loading";

const contractor_id = localStorage.getItem('temp')

export default function Contractors () {

	const listContractors = useAllFetch(['listcontractors'], '/contractors')

	if (listContractors.isPending) {
		return <Loading />;	}

	return (
		<div className='container'>
			<div className="title-2 mt-2 ml-3">
				<Breadcrumb>
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbSeparator />
						<BreadcrumbItem>
							<BreadcrumbLink href={window.location.href}>List Contractor</BreadcrumbLink>
						</BreadcrumbItem>
					</BreadcrumbList>
				</Breadcrumb>
			</div>
			<Table responsive="true" striped="true" size="sm" className="mt-2 mr-2 ml-3" style={{width: '98%'}}>
					<thead align="left">
							<tr>
									<th></th>
									<th>NAME</th>
									<th>ADDRESS</th>
									<th>EMAIL</th>
									<th>PHONE</th>
									<th>CONTACT</th>
									<th>STATUS</th>
									<th>LOGO</th>
									<th>ACTION</th>
							</tr>
					</thead>
					<tbody>
							{listContractors.data?.map(contractor => <Contractor contractor={contractor} key={contractor.id} /> )}
					</tbody>            
			</Table>
		</div>
	)
}
