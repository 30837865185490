import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import config_ip from './config_ip.js';

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
// Importacion de componentes
import Jobs from "./components/Jobs";
import JobForm from "./components/Jobs/JobForm";
import Editjob from "./components/Jobs/Editjob";
import Insurances from "./components/Insurances";
import InsurForm from "./components/Insurances/InsurForm";
import Editinsur from "./components/Insurances/Editinsur";
import Contractors from "./components/Contractors";
import ContractorForm from "./components/Contractors/ContractorForm";
import Editcontractor from "./components/Contractors/Editcontractor";
import Assigninsu from "./components/Contractors/Assigninsu";
import Assignrobbie from "./components/Contractors/Assignrobbie";
import Users from "./components/Users";
import UserForm from "./components/Users/UserForm";
import Edituser from "./components/Users/Edituser";
import Adjusters from "./components/Adjusters";
import AdjusterForm from "./components/Adjusters/AdjusterForm";
import Editadjuster from "./components/Adjusters/Editadjuster";
import Areas from "./components/Areas";
import AreaForm from "components/Areas/AreaForm";
import Editarea from"./components/Areas/Editarea";
import Lgenareas from "./components/Areas/Lgenareas";
import Robbies from "./components/Robbies";
import RobbieForm from "./components/Robbies/RobbieForm";
import EditRobbie from "./components/Robbies/EditRobbie";
import Equipments from "./components/Equipments";
import EquipmentForm from "./components/Equipments/EquipmentForm";
import EditEquipment from "./components/Equipments/EditEquipment";
import Params from "./components/Params";
import ParamsForm from "./components/Params/ParamsForm";
import EditParams from "./components/Params/EditParams";
import Linegraph from "./components/Linegraph";
import SignInPageNew from "./components/SignUpNew";
// import Logout from "./components/Logout";
import Map from "./components/Map/";
// import Analytics from "./components/Analytics";
import GenPub from "./components/GenPub";
import Costs from "./components/Costs";
import Invoice from './components/Invoice';
import Certificate2 from "./components/Certificate2";
import Actionlogs from "./components/Actionlogs";
import "./fonts/GalanoGrotesqueDEMO-Bold.ttf"
import { useAuth } from "@clerk/clerk-react"
import { useUser } from "@clerk/clerk-react";
import Drift from 'react-driftjs';
// Componentes para generar la autorizacion y vista publica
import MapPub from "./components/MapPub/";
import Authorization from "./components/Authorization/";
import Viewpub from "./components/Areas/Viewpub";
import LinegraphPub from "./components/AreasPub/LinegraphPub";

function App() {

  const { userId, isLoaded } = useAuth()
  const { user } = useUser()
  const [user_email, setEmail] = useState('')
  const [renderDrift, setRenderDrift] = useState(false);

  // Function to safely set user metadata in localStorage
  const updateUserMetadata = React.useCallback(() => {
    if (!user || !user.publicMetadata) return;
    const { contractor_id, insurco_id, temp } = user.publicMetadata;
    localStorage.setItem('contractor_id', contractor_id || '');
    localStorage.setItem('insurco_id', insurco_id || '');
    localStorage.setItem('temp', temp || '');
    localStorage.setItem('name', user.primaryEmailAddress.emailAddress)
    localStorage.setItem('language', 'en')
    setEmail(user.primaryEmailAddress.emailAddress)

  }, [user]);

  // Effect hook to update metadata when user or userId changes
  React.useEffect(() => {
    if (userId && isLoaded) {
      updateUserMetadata();
      // we need to update the user email in the state

    }
  }, [userId, isLoaded, updateUserMetadata]);

  useEffect(() => {
    if (isLoaded && userId) {
      setRenderDrift(true); // Allow Drift rendering once user is loaded
    }
  }, [isLoaded, userId]);

  const driftComponent = renderDrift ? (
    <Drift appId={config_ip.drift_id} attributes={{ user: user_email, contractor_id: localStorage.getItem('contractor_id'), name: user_email }} />
  ) : null;

  return (

    <div className="pt-20">

      <Router>
        <Routes>

          {!isLoaded ? null : userId ? (
            <>
              {/* Your routing logic remains the same */}
              <Route path="/" element={<Navigate to={'/jobs/' + user.publicMetadata.contractor_id} />} />
              <Route path="/jobs/:id" element={<Jobs />} />
              <Route path="/lareas/:id" element={<Areas />} />
              <Route path="/daily/:jobid/:areaid" element={<Linegraph />} />
              <Route path="/ejob/:jobid/:contractor_id" element={<Editjob  />} />
              {/* <Route path="/ejob/:id/:contractor_id" render={(props) => <Editjob {...props} ronly={false} />} /> */}
              <Route path="/addArea/:id" element={<AreaForm />} />
              <Route path="/genpub/:id/:contractor" element={<GenPub />} />
              <Route path="/map/:contractor_id/:status" element={<Map />} />
              <Route path="/earea/:id" element={<Editarea />} />
              <Route path="/addJob" element={<JobForm />} />

              {/* Routes for the superuser */}
              {/* contractors pannel */}
              <Route path="/lcontractors" element={<Contractors />} />
              <Route path="/econtractor/:id" element={<Editcontractor />} />
              <Route path="/addcontractor" element={<ContractorForm />} />
              <Route path="/assigninsu/:contractorid/:name" element={<Assigninsu />} />
              <Route path="/assignrobbie/:contractor_id/:name" element={<Assignrobbie />} />

              {/* users pannel  */}
              <Route path="/adduser" element={<UserForm />} />
              <Route path="/lusers" element={<Users />} />
              <Route path="/euser/:id" element={<Edituser />} />

              {/* insurances pannel */}
              <Route path="/linsur" element={<Insurances />} />
              <Route path="/addInsur" element={<InsurForm />} />
              <Route path="/einsur/:id" element={<Editinsur />} />

              {/* adjusters pannel */}
              <Route path="/ladjuster" element={<Adjusters />} />
              <Route path="/addAdjuster" element={<AdjusterForm />} />
              <Route path="/eadjuster/:id" element={<Editadjuster />} />

              {/* areas pannel */}
              <Route path="/lgenareas" element={<Lgenareas />} />

              {/* robbies pannel */}
              <Route path="/lRobbies" element={<Robbies />} />
              <Route path="/addRobbie" element={<RobbieForm />} />
              <Route path="/erobbie/:id" element={<EditRobbie />} />

              {/* equipments pannel */}
              <Route path="/lEquipments" element={<Equipments />} />
              <Route path="/addEquipment" element={<EquipmentForm />} />
              <Route path="/eEquipment/:id" element={<EditEquipment />} />

              {/* params pannel */}
              <Route path="/lparams" element={<Params />} />
              <Route path="/addParams" element={<ParamsForm />} />
              <Route path="/eparams/:id" element={<EditParams />} />

              {/* analytics pannel */}
              {/* <Route path="/analytics" element={<Analytics />} /> */}

              {/* costs pannel */}
              <Route path="/lcosts/:job_id" element={<Costs />} />

              {/* invoice pannel */}
              <Route path="/invoice/:job_id" element={<Invoice />} />

              {/* certificate pannel */}
              <Route path="/certificate2/:jobid" element={<Certificate2 />} />

              {/* actionlogs pannel */}
              <Route path="/getactionlogs" element={<Actionlogs />} />

            </>
          ) : (
            <>
              <Route path="/public_map" element={<MapPub />} />
              <Route path="/authorization/:id" element={<Authorization />} />
              <Route path="/linktemp/:id" element={<Viewpub />} />
              <Route path="/dailyPub/:link1/:link2" element={<LinegraphPub />} />
              <Route path="/" element={<SignInPageNew />} />
            </>
          )}
        </Routes>
      </Router>
      {driftComponent}
    </div>

  );
}

export default App;
