import React from "react";
import { Button } from "@/components/ui/button.jsx";
import {
  TableRow,
  TableCell,
} from '@/components/ui/table.jsx'
import { 
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu.jsx";
import { MoreHorizontal } from "lucide-react";

export default function Insurance (props) {
  const { aseguradora } = props

  return (
    <TableRow align="left">
      <TableCell className="p-0">{aseguradora.id}</TableCell>
      <TableCell className="p-0">{aseguradora.insurancename}</TableCell>
      <TableCell className="p-0">{aseguradora.insuranceaddress}</TableCell>
      <TableCell className="p-0">{aseguradora.insurancephone}</TableCell>
      <TableCell className="p-0">{aseguradora.insurancecountry}</TableCell>
      <TableCell className="p-0">{aseguradora.id_branches}</TableCell>
      <TableCell className="p-0">{aseguradora.id_qbo}</TableCell>
      <TableCell className="p-0">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0 ">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end'>
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => window.location.href="/einsur/" + aseguradora.id}>
            Edit Insurance
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => window.location.href="/addinsur/"}>
            Create Insurance
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      </TableCell>
    </TableRow>
  )
}
