import React from "react";
import { Loader2 } from "lucide-react";

export const Loading = () => {
return (
    <div className="fixed inset-0 flex justify-center items-center">
        <>
            <Loader2 className="mr-2 h-5 w-5 animate-spin" />
            Loading...
        </>
    </div>
);
};

export default Loading;
