import React, { useState } from "react";
import ip from '../../config_ip';
import { StatusLight } from "../UI/StatusLight";
import {
  TableRow,
  TableCell
} from "@/components/ui/table.jsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu.jsx";
import { Button } from "@/components/ui/button.jsx";
import Allfetch from '../Allfetch';
import { useAuth } from "@clerk/clerk-react";
import { MoreHorizontal } from "lucide-react";
import { useTranslation } from "react-i18next";

const name = localStorage.getItem('name')
const contractor_id = localStorage.getItem('contractor_id')

export default function Area(props) {

  const { t } = useTranslation()
  const { area } = props

  // const { area, claimtime, areatime, dryingtime } = props
  const { getToken } = useAuth()

  const [statusR, setStatusR] = useState([])

  const changeStatusF = async (status, jobid, areaid) => {
    if (status === 'I') {
      const tokenu = await getToken()
      await fetch(ip.url + "/events", {
        method: "post",
        body: JSON.stringify({ areaid: areaid, why: "Restart area", user: name, type: "R" }),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
    }
    Allfetch('/changeStatus/' + status + '/' + jobid + '/' + areaid, setStatusR, null, null, await getToken())
    if (statusR !== undefined) {
      window.location.pathname = "/jobs/" + contractor_id
    }
  }

  function renderAreaStatus(area) {
    let status = '';
    let label = '';

    switch (area.status) {
      case 'N':
        if (area.name_raspi === 'UNASSIGNED') {
          status = 'paused';
          label = 'Unassigned';
        } else {
          status = 'newarea';
          label = 'New';
        }
        break;
      case 'P':
        status = 'paused';
        label = 'Paused';
        break;
      case 'F':
        status = 'finished';
        label = 'Finished';
        break;
      case 'I':
        if (area.name_raspi === 'UNASSIGNED') {
          status = 'paused';
          label = 'Unassigned';
        } else {
          status = 'progress';
          label = 'In progress';
        }
        break;
      case 'T':
        status = 'paused';
        label = 'Fail Thermo';
        break;
      default:
        status = 'paused';
        label = 'Fail Amperage';
        break;
    }

    return (
      <div className="flex flex-row items-center text-base font-medium">
        <StatusLight status={status} disabled={false} />
        {label}
      </div>
    );
  }

  return (
    <TableRow>
      <TableCell className ="w-5/12" onClick={() => (window.location.href = "/daily/" + area.job_id + "/" + area.id)}>
        <p className="text-base font-semibold">
          {area.aname}
        </p>
        <p className="text-sm font-normal text-muted-foreground truncate ...">
          {area.description}
        </p>
        {contractor_id !== null ?
          <p className="text-sm font-normal text-muted-foreground">{area.job_id}{area.id}</p> : null}
      </TableCell>
      <TableCell onClick={() => (window.location.href = "/daily/" + area.job_id + "/" + area.id)}>
        {renderAreaStatus(area)}
      </TableCell>
      <TableCell onClick={() => (window.location.href = "/daily/" + area.job_id + "/" + area.id)}>
        <div className="text-sm font-normal text-muted-foreground flex flex-column items-start">
          <div>
            {t('ElapsedTime')}: {area.claim_time !== null ? parseFloat(area.claim_time).toFixed(2) : 0}h
          </div>
          <div>
            {t('DemoTime')}: {area.area_time !== null ? parseFloat(area.area_time - area.drying_time).toFixed(2) : 0}h
          </div>
          <div>
            {t('DryingTime')}: {area.drying_time !== null ? parseFloat(area.drying_time).toFixed(2) : 0}h
          </div>
        </div>
      </TableCell>

      <TableCell onClick={() => (window.location.href = "/daily/" + area.job_id + "/" + area.id)}>
        <div className="flex flex-column items-start">
          <p className="text-base font-medium">
            {area.humisetpoint}{t('TargetHR')}
          </p>
        </div>
        <div className="text-sm font-normal text-muted-foreground">{area.dh} {t('Dehumidifier')}</div>
        <div className="text-sm font-normal text-muted-foreground ">{area.bl} {t('AirMover')}</div>
      </TableCell>

      <TableCell onClick={() => (window.location.href = "/daily/" + area.job_id + "/" + area.id)}>
        <div className="row-data-container">

          <div className="flex flex-row items-center text-base font-medium">
            {
              area.status === 'F' ?
                <StatusLight status='finished' disable={false} />
                : area.is_ws_connected === 1 || area.is_ws_connected === true ?
                  <StatusLight status='progress' disabled={false} />
                  :
                  <StatusLight status='paused' disabled={false} />
            }
            {area.name_raspi}
          </div>
          {/* <small>{area.usemoisture === 'N' ? '' : 'External Moisture Sensor'}</small> */}
        </div>
      </TableCell>

      <TableCell className="align-middle">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0 ">
              <span className="sr-only">{t('OpenMenu')}</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t('Actions')}</DropdownMenuLabel>
            {(area.status === 'I' || area.status === 'F' || area.status === 'P') && contractor_id !== null && (
              <>
                <DropdownMenuItem onClick={() => (window.location.href = "/daily/" + area.job_id + "/" + area.id)}>
                  {t('DetailsLogs')}
                </DropdownMenuItem>
              </>
            )}
            {area.status !== 'F' && contractor_id !== null && (
              <>
                <DropdownMenuItem onClick={() => (window.location.href = "/earea/" + area.id)}>
                  {t('EditArea')}
                </DropdownMenuItem>
              </>
            )}
            {area.status === "P" && contractor_id !== null && (
              <DropdownMenuItem onClick={() => changeStatusF("I", area.job_id, area.id)}>
                {t('UnpauseArea')}
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  )
}
