import "./Area.scss";
import { StatusLight } from "../UI/StatusLight";
import { RobbieButton } from "../UI/RobbieButton";
import graph from '../UI/RobbieButton/icons/graph.svg';
import ReactTooltip from 'react-tooltip';
import { TableRow, TableCell } from "@/components/ui/table.jsx";

const contractor_id = localStorage.getItem('contractor_id')

export default function Areap(props) {

  const { area } = props
  const { times } = props
  const { link1, link2 } = props

  const timerenderer = times.filter((time) => (
    time.id === area.id
  ))

  return (

    <TableRow align="left">
      <TableCell>
        <ReactTooltip />

        <p className="row-data-container">
          <div className="main_text"> {area.aname}</div>
          <br />
          <small>{area.description}</small><br />
          {contractor_id !== null ?
            <small>{area.job_id}{area.id}</small> : null}
        </p>
      </TableCell>

      <TableCell>
        {area.status === 'N' && area.name_raspi === 'UNASSIGNED' ?
          <div className="inline"><StatusLight status='paused' disabled={false} />UNASSIGNED</div>
          :
         area.status === 'N' ?
          <div className="inline"><StatusLight status='newarea' disabled={false} />New</div>
          : area.status === 'P' ?
            <div className="inline"><StatusLight status='paused' disabled={false} />Paused</div>
            : area.status === 'F' ?
              <div className="inline"><StatusLight status='finished' disabled={false} />Finished</div>
              : area.status === 'I' && area.name_raspi === 'UNASSIGNED' ?
                <div className="inline"><StatusLight status='paused' disabled={false} />UNASSIGNED</div>
                : area.status === 'I' ?
                  <div className="inline"><StatusLight status='progress' disabled={false} />In progress</div>
                  : area.status === 'T' ?
                    <div className="inline"><StatusLight status='paused' disabled={false} />Fail Thermo</div>
                    : <div className="inline"><StatusLight status='paused' disabled={false} />Fail Amperage</div>
        }
        <p><small></small></p>
      </TableCell>

      <TableCell>
        <p className="row-data-container">
          <div className="main_text"> Elapsed Time: {timerenderer[0]?.claim_time !== null ? parseFloat(timerenderer[0]?.claim_time).toFixed(2) : 0}h</div>
          <br />
          <small>Demo Time: {timerenderer[0]?.area_time !== null ? parseFloat(timerenderer[0]?.area_time - timerenderer[0]?.drying_time).toFixed(2) : 0}h</small><br />
          <small>Drying Time: {timerenderer[0]?.drying_time !== null ? parseFloat(timerenderer[0]?.drying_time).toFixed(2) : 0}h</small>
        </p>
      </TableCell>

      <TableCell>
        <p className="row-data-container">
          <div className="main_text">
            {area.humisetpoint}% Target R.H.
          </div>
          <br />
          <small>{area.dh} Dehumidifier</small><br />
          <small>{area.blowers} Blowers</small>
        </p>
      </TableCell>

      <TableCell>
        <div className="row-data-container">
          <div className="main_text">
            {
              area.status === 'F' ?
                <StatusLight status='finished' disable={false} /> 
              : area.is_ws_connected === 1 ?
                  <StatusLight status='progress' disabled={false} />
                :
                  <StatusLight status='paused' disabled={false} />
            }
            {area.name_raspi}
          </div>

          <br />
          {/* <small>{area.usemoisture === 'N' ? '' : 'External Moisture Sensor'}</small> */}
        </div>
      </TableCell>
      <TableCell>
        {(area.status === 'I' || area.status === 'P') && contractor_id === null ?
          <RobbieButton href={"/dailyPub/" + link1 + "/" + link2} icon={graph} data-tip="Click here to see the details and logs of the area" />
          : ''}
      </TableCell>
    </TableRow>
  )
}
