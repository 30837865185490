import React from "react";
import ip from '../../config_ip';
import { useForm } from "react-hook-form";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

import { Loading } from "../UI/Loading";
import { useTranslation } from "react-i18next";

const temp = localStorage.getItem("temp");
const contractor_id = localStorage.getItem("contractor_id");
const nameuser = localStorage.getItem('name')
const area_id = window.location.pathname.split('/')[2]
let tokenu = ''

export default function Editarea() {

  const { t } = useTranslation()
  const { getToken } = useAuth();

  const area = useAllFetch(['area'], '/getarea/' + area_id)
  const robbie = useAllFetch(['robbie'], '/robbie/' + temp + '/' + contractor_id)
  const equipments = useAllFetch(['equipments'], '/lequipDhBl')

  const mutation = useMutation({
    mutationFn: async (data) => {
      tokenu = await getToken()
      const response = await fetch(ip.url + '/updatearea/' + area_id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )

  const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = '/lareas/' + area.data?.job_id
          //queryClient.invalidateQueries({ queryKey: ['areas, robbie, equipments'] })
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
  }

  const areaSchema = object().shape({
    aname: string().min(2).max(50, "Must be lesser than or equal 50"),
    description: string(),
    name_raspi: string().min(5, "Too Short!").max(11, "Too Long!"),
    humisetpoint: number()
      .min(2, "Too Short!")
      .max(3, "Too Long!")
      .lessThan(60, "Must be lesser than 60")
      .moreThan(29.99, "Must be grater than or equal 30"),
    equipment_dh_id: string(),
    dh: number()
      .min(1, "Must be greater than or equal 1")
      .max(2, "Must be less than 3")
      .typeError("must be a number")
      .lessThan(3),
    equipment_bl_id: string(),
    blowers: number()
      .min(0, "must be greater than or equal 0")
      .max(5, "Must be less than 6")
      .typeError("must be a number")
      .lessThan(6),
  }).required();

  const form = useForm({
    resolver: yupResolver(areaSchema),
    values: {
      id: area.data?.id,
      job_id: area.data?.job_id,
      aname: area.data?.aname,
      description: area.data?.description,
      name_raspi: area.data?.name_raspi,
      humisetpoint: area.data?.humisetpoint,
      equipment_dh_id: area.data?.equipment_dh_id.toString(),
      dh: area.data?.dh,
      equipment_bl_id: area.data?.equipment_bl_id.toString(),
      blowers: area.data?.blowers,
      user: nameuser,
    }
  });

  if (area.isLoading || robbie.isLoading || equipments.isLoading) {
    return <Loading />;  }

  return (
    <div className="container">
      <div className="mb-3">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>{t('Claims')}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={"/lareas/" + area.data?.job_id}>{t('Areas')}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>{t('EditArea')}</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="aname"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('NameArea')}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t('EnterNameArea')}
                    {...field}
                    {...form.register("aname")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('Description')}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t('EnterAreaDescription')}
                    {...field}
                    {...form.register("description")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="name_raspi"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('RobbieId')}</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder='Select Robbie Id' />
                    </SelectTrigger>
                    <SelectContent>
                    {robbie.data.data?.sort((a, b) => a.name_raspi.localeCompare(b.name_raspi)).map((robbie) => (
                        <SelectItem
                          key={robbie.id}
                          value={robbie.name_raspi}
                        >
                          {robbie.name_raspi}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="humisetpoint"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('Humisetpoint')}</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter humisetpoint"
                    {...field}
                    {...form.register("humisetpoint")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="equipment_dh_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('DehumidifierType')}</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Humidifier type" />
                    </SelectTrigger>
                    <SelectContent>
                      {equipments.data?.[0].map((equipment) => (
                        <SelectItem
                          key={equipment.id}
                          value={equipment.id.toString()}
                        >
                          {equipment.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="dh"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('DehumidifierQty')}</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Dehumidifiers Qty (1-2)"
                    {...field}
                    {...form.register("dh")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="equipment_bl_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('BlowerType')}</FormLabel>
                <FormControl>
                  <Select
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Blower type" />
                    </SelectTrigger>
                    <SelectContent>
                      {equipments.data?.[1].map(
                        (equipment) =>
                          <SelectItem
                            key={equipment.id}
                            value={equipment.id.toString()}
                          >
                            {equipment.name}
                          </SelectItem>
                        )
                      }
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          {form.typebl !== "None" && (
            <FormField
              control={form.control}
              name="blowers"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('AirMoverQty')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Blower count (0-5)"
                      {...field}
                      {...form.register("blowers")}
                      value={field.value}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          )}
          <Button type="submit">{t('Save')}</Button>
        </form>
      </Form>
    </div>
  );
}
