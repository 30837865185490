import React from "react"
import {
  Table,
  TableHeader,
  TableHead,
  TableRow
} from "@/components/ui/table.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import Areaadmin from "./Areaadmin"

import { Loading } from "../UI/Loading";

const contractor_id = localStorage.getItem('contractor_id')

export default function Lgenareas() {

  const areas = useAllFetch(['areas'], '/lareas')

  if (areas.isPending ) {
    return <Loading />;  }

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Status Areas</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>
              DIRECCION
            </TableHead>
            <TableHead>
              STATUS
            </TableHead>
            <TableHead>
              SETUP
            </TableHead>
            <TableHead>
              CONTRACTOR
            </TableHead>
            <TableHead>
              ROBBIE ID
            </TableHead>
          </TableRow>
          {areas.data.map((area) => <Areaadmin key={area.areaid} area={area} times={[]} />)}
        </TableHeader>
      </Table>
    </div>
  )
}
