import styled from '@emotion/styled';
import { css } from '@emotion/react';

const progress = css`
background: #03FF3B;
box-shadow: 0px 0px 1px 1px rgba(5, 255, 0, 0.25), inset 1px 1px 2px -1px rgba(0, 0, 0, 0.25);
`;

const finished = css`
background: rgba(227, 229, 230, 0.15);
box-shadow: inset 1px 1px 2px -1px rgba(0, 0, 0, 0.25);

`;

const paused = css`
background: #FF00D6;
box-shadow: 0px 0px 1px 1px rgba(255, 0, 229, 0.25), inset 1px 1px 2px -1px rgba(0, 0, 0, 0.25);

`;

const error = css`
background: #ff4800;
box-shadow: 0px 0px 1px 1px rgba(255, 0, 0, 0.25), inset 1px 1px 2px -1px rgba(0, 0, 0, 0.25);

`;

const newarea = css`
background: #FF00D6;
box-shadow: 0px 0px 1px 1px rgba(255, 0, 229, 0.25), inset 1px 1px 2px -1px rgba(0, 0, 0, 0.25);

`;

const StatusLightStylesType = {
  progress,
  finished,
  newarea,
  paused,
  error
};

export const StatusLightStyled = styled.div`
  ${(props) => StatusLightStylesType[props.status]};
  height: 12px;
  width: 12px;
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  min-width: 12px;
  color: black;
  border-radius: 50%;
  border: none ;
  margin-right: 5px;
`;
