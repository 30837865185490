import React, { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { ChevronDown, LogOut } from "lucide-react";
import logoBlue from "../../LogoBlue.svg";
import logoPurple from "../../LogoPurple.svg";
import { Button } from "@/components/ui/button";
import { useUser, useClerk } from "@clerk/clerk-react";
import ip from '../../config_ip';
// import { useTranslation } from "react-i18next";

console.log(ip.is_production);


export default function NavBar() {

  const arraySlash = window.location.pathname.split("/");
//  const { i18n } = useTranslation()
  const { user, isLoaded } = useUser();
  const { signOut } = useClerk();
//  const { getToken } = useAuth()
  const [contractor_id, setContractorId] = useState(Number(localStorage.getItem("contractor_id")));
  const [temp, setTempId] = useState(Number(localStorage.getItem("temp")));
  const [name, setName] = useState(localStorage.getItem("name"))
//  const [ language, setLanguage] = useState('en')

  useEffect(() => {
    if (isLoaded && user && user.publicMetadata) {
      const { contractor_id, temp } = user.publicMetadata || "";
      setContractorId(contractor_id);
      setTempId(temp);
      setName(name)
//      setLanguage(localStorage.getItem("language"))
    }
  }, [isLoaded, user, name]);

  const handleLogout = async () => {
    try {
      await signOut(); // Attempt to sign out
      // Clear specific localStorage items after successful sign out
      localStorage.removeItem("contractor_id");
      localStorage.removeItem("temp");
      localStorage.removeItem("name");
      localStorage.removeItem("language")
      // Redirect or perform additional actions after logout if needed
      window.location.href = "/"; // Redirect to sign-in page or home page
    } catch (error) {
      console.error("Failed to sign out:", error);
    }
  };

  const isAdmin = () => {
    return temp === "1" && (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button>
            <ChevronDown className="h-4 w-4" />
            Admin
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Contractors</DropdownMenuLabel>
          <DropdownMenuItem
            onClick={() => (window.location.href = "/lcontractors")}
          >
            List Contractors
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuLabel>Users</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => (window.location.href = "/lusers")}>
            List Users
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuLabel>Insurances</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => (window.location.href = "/linsur")}>
            List Insurances
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuLabel>Adjusters</DropdownMenuLabel>
          <DropdownMenuItem
            onClick={() => (window.location.href = "/ladjuster")}
          >
            List Adjusters
          </DropdownMenuItem>
          <DropdownMenuSeparator />

          <DropdownMenuLabel>Robbies</DropdownMenuLabel>
          <DropdownMenuItem
            onClick={() => (window.location.href = "/lRobbies")}
          >
            List Robbies
          </DropdownMenuItem>
          <DropdownMenuSeparator />

          <DropdownMenuLabel>Equipments</DropdownMenuLabel>
          <DropdownMenuItem
            onClick={() => (window.location.href = "/lequipments")}
          >
            List Equipments
          </DropdownMenuItem>
          <DropdownMenuSeparator />

          <DropdownMenuLabel>Parameters</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => (window.location.href = "/lparams")}>
            List Parameters
          </DropdownMenuItem>
          <DropdownMenuSeparator />

          <DropdownMenuLabel>Areas</DropdownMenuLabel>
          <DropdownMenuItem
            onClick={() => (window.location.href = "/lgenareas")}
          >
            List All Areas
          </DropdownMenuItem>
          <DropdownMenuSeparator />

          <DropdownMenuItem
            onClick={() => (window.location.href = "/getactionlogs")}
          >
            List Actions logs
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  };

  // async function changeLang () {
  //   const tokenu = await getToken()
  //   if (language === "en") {
  //     i18n.changeLanguage('fr')
  //     setLanguage("fr")
  //     localStorage.setItem("language", "fr")
  //     console.log('Changed to French')
  //     await fetch(ip.url + "/changeLang/fr/" + localStorage.getItem('name'), {
  //       method: "put",
  //       headers: {
  //         "Accept": "application/json",
  //         "Content-Type": "application/json",
  //         "Authorization": `Bearer ${tokenu}`
  //       },
  //     })
  //   } else {
  //     i18n.changeLanguage('en')
  //     setLanguage("en")
  //     localStorage.setItem("language", "en")
  //     console.log('Changed to English')
  //     await fetch(ip.url + "/changeLang/en/" + localStorage.getItem('name'), {
  //       method: "put",
  //       headers: {
  //         "Accept": "application/json",
  //         "Content-Type": "application/json",
  //         "Authorization": `Bearer ${tokenu}`
  //       },
  //     })
  //   }
  // }

  const isContractor = () => {
    if (contractor_id !== "1") {
      if (arraySlash[1] === "jobs") {
        return (
          <>
            {/* <Button variant='robbie' onClick={changeLang}>
              {localStorage.getItem("language") === "en" ? 'French' : 'English'}
            </Button> */}
            <Button variant='robbie' onClick={() => (window.location.href = "/addJob")}>
              New Claim
            </Button>
          </>
        );
      } else if (arraySlash[1] === "lareas") {
        return (
          <Button
            onClick={() => (window.location.href = "/addArea/" + arraySlash[2])}
            variant='robbie'
          >
            New Area
          </Button>
        );
      }
    }
    // } else {  // Esto es nuevo, no hay else
    //   return (
    //     <Button variant='robbie' onClick={changeLang}>  {/* Boton cambio de idioma Octubre 17 de 2024 */}
    //       {localStorage.getItem("language") === "en" ? 'French' : 'English'}
    //     </Button>
    //   )
    // }
    return null;
  };

  // const isInsurco = () => {
  //   return contractor_id &&
  //     insurco_id !== "0" &&
  //       (temp === "2" || temp === "3") && (
  //       <Button variant='robbie' onClick={() => (window.location.href = "/analytics")}>
  //         Analytics
  //       </Button>
  //   )
  // };

  const isLogged = () => {
    return user != null && (
      <Button variant='ghost' onClick={handleLogout}>
        <LogOut className="h-4 w-4" />
      </Button>
    )
  };

  return (
    <div className="bg-white fixed top-0 left-0 w-full z-10 ">
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <a href="/" className="flex items-center">
            <img
              src={ip.is_production ? logoBlue : logoPurple}
              alt="Robbie Insurtech Logo"
              className="h-8 w-auto"
            />
          </a>
          {user &&
            <div className="flex items-center space-x-4">
              {isAdmin()}
              {isContractor()}
              {isLogged()}
            </div>
          }
        </div>
      </div>
    </div>
  );
}
