import React from 'react';
import Insurance from './Insurance';
import {
	Table,
	TableBody,
	TableRow,
	TableHeader,
	TableHead,
 } from '@/components/ui/table.jsx';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { useAllFetch } from '../CustomHooks/useAllFetch';

import { Loading } from "../UI/Loading";

const contractor_id = localStorage.getItem('temp')

export default function Insurances () {

	const insurances = useAllFetch(['insurances'], '/insurances')
	
	if (insurances.isPending) {
		return <Loading />;	}
	
	if (insurances.data.length !== 0) {
		return (
			<div className='container'>
				<Breadcrumb>
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbSeparator />
						<BreadcrumbItem>
							<BreadcrumbLink href={window.location.href}>List Insurances</BreadcrumbLink>
						</BreadcrumbItem>
					</BreadcrumbList>
				</Breadcrumb>
				<div className="title-2 mt-2 ml-3">
					Insurances
				</div>
				<Table responsive="true" striped="true" size="sm" className="mt-2 mr-2 ml-3" style={{width: '98%'}}>
					<TableHeader align="left">
						<TableRow>
							<TableHead></TableHead>
							<TableHead>NAME</TableHead>
							<TableHead>ADDRESS</TableHead>
							<TableHead>PHONE</TableHead>
							<TableHead>COUNTRY</TableHead>
							<TableHead>BRANCHE</TableHead>
							<TableHead>ID_QBO</TableHead>
							<TableHead>ACTION</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{ insurances.data?.map(aseguradora => <Insurance aseguradora={aseguradora} key={aseguradora.id} /> ) }
					</TableBody>            
				</Table>
			</div>
		)
	} else {
		return (
			<div>
				<h3>There is not insurances created</h3>
			</div> )
	}
}
