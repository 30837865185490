import { useQuery } from "@tanstack/react-query"
import { useAuth } from "@clerk/clerk-react"
import ip from '../../config_ip'

export const useAllFetch = (qkey, endpoint) => {

  const { getToken } = useAuth()

  const getData = async () => {
		const token = await getToken()
		const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    try {
  		const resp = await fetch(ip.url + endpoint, options)

      if (resp.ok) {
        const resp2 = await resp.json()
        if (resp2.rows) {
          return resp2.rows // Trae datos de MariaDB
        } else {
          if (resp2.data.data) {
            if (resp2.data.count > 1) {
              return resp2.data // Trae un arreglo de registros desde postgresql en data
            } else {
              return resp2.data.data // Trae un registro desde postgresql
            }
          } else {
            return resp2.data
          }
        }
      } else {
        console.error(resp.statusText)
      }
    } catch {
      console.error("Error fetch")
    }
	}
  
  // const query = useQuery({queryKey: [qkey], queryFn: getData, retry: 4, enable: qkey.length === 2 ? !!qkey[1] : true})
  const query = useQuery({queryKey: [qkey], queryFn: getData, retry: 4})

  return query
}