import React, { useState, useEffect } from "react";
import Job from "./Job";
import Allfetch from "../Allfetch";
import { useAllFetch } from "components/CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";

import { Loading } from "../UI/Loading";
import { NoResults } from "../UI/NoResults";

import { format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Calendar as CalendarIcon, MapPinned } from "lucide-react";
import { useTranslation } from 'react-i18next';

export default function Jobs() {

  const { t } = useTranslation()
  const contractor_Id = window.location.pathname.split("/")[2];
  const contractorId = localStorage.getItem("contractor_id")
  const insurcoId = localStorage.getItem("insurco_id")
  const temp = localStorage.getItem("temp")
  let totalReg = 0

  const itemsPerPage = 8;
  const [allRegisters, setAllRegisters] = useState([])
  const [totalRecords, setTotalRecords] = useState([])
  const [currentRegisters, setCurrentRegisters] = useState([]);
  const [search1, setSearch1] = useState("");
  const [status, setStatus] = useState("N");
  const [startDate, setStartDate] = useState(new Date("1 march 2021"));
  const [finalDate, setFinalDate] = useState(new Date());
  const { getToken } = useAuth();
 
  const jobs = useAllFetch(['jobs'], '/jobs/' + contractor_Id + '/' + status + '/' + insurcoId)
  const tilaclaim = useAllFetch(['tilaclaim'], '/tilaclaim/' + contractor_Id)

  useEffect(() => {
    setAllRegisters(jobs.data)
    setCurrentRegisters(jobs.data?.slice(0, itemsPerPage))
    setTotalRecords(jobs.data?.length)
    return () => {
      setSearch1("");
      setStatus("N");
      setStartDate(new Date("1 march 2021"));
      setFinalDate(new Date());
    };
  }, [jobs.data]);

  const onChange = async (e) => {
    e.persist();
    if (e.target.name === "filter") {
      setStatus(e.target.value);
      Allfetch(
        "/jobs/" + contractorId + "/" + e.target.value + "/" + insurcoId,
        (data) => {
          setAllRegisters(data);
          setTotalRecords(data.length);
          setCurrentRegisters(data.slice(0, itemsPerPage));
        },
        null,
        null,
        await getToken()
      );
    } else if (e.target.name === "search") {
      setSearch1(e.target.value);
      filterElements(e.target.value);
    }
  };

  const filterElements = (search1) => {
    var search2 = allRegisters.filter(
      (item) =>
        item.description.toLowerCase().includes(search1.toLowerCase()) |
        item.created_at.includes(search1.toLowerCase()) |
        item.address.toLowerCase().includes(search1.toLowerCase()) |
        item.certificate.toString().includes(search1.toLowerCase())
    );
    setTotalRecords(search2.length);
    setCurrentRegisters(search2.slice(0, itemsPerPage));
  };

  const onPageChanged = (newPage) => {
    const offset = (newPage - 1) * itemsPerPage;
    const newCurrentRegisters = allRegisters?.slice(
      offset,
      offset + itemsPerPage
    );
    setCurrentRegisters(newCurrentRegisters);
    setCurrentPage(newPage);
  };

  const handleFinalDateChange = async (date) => {
    setFinalDate(date);
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const startDay = startDate.getDate();
    const startDateJoin = startYear + "-" + startMonth + "-" + startDay;
    const finalMonth = date?.getMonth() + 1;
    const finalYear = date?.getFullYear();
    const finalDay = date?.getDate();
    const finalDateJoin = finalYear + "-" + finalMonth + "-" + finalDay;
    Allfetch(
      "/datejobs/" +
        contractorId +
        "/" +
        status +
        "/" +
        startDateJoin +
        "/" +
        finalDateJoin,
      (data) => {
        setAllRegisters(data);
        setTotalRecords(data.length);
        setCurrentRegisters(data.slice(0, itemsPerPage));
      },
      null,
      null,
      await getToken()
    );
  };

  if (!jobs.isPending) {
    totalReg = totalRecords;
  }

  const handleValueChange = async (newValue) => {
    setStatus(newValue);
    setAllRegisters([]);
    setCurrentRegisters([]);
    Allfetch(
      "/jobs/" + contractorId + "/" + newValue + "/" + insurcoId,
      (data) => {
        setAllRegisters(data);
        setTotalRecords(data.length);
        setCurrentRegisters(data.slice(0, itemsPerPage));
      },
      null,
      null,
      await getToken()
    );
    // You can perform additional actions here based on the selected value if needed
  };

  const totalPageCount = Math.ceil(allRegisters?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const getPageNumbers = () => {
    const totalPageCount = Math.ceil(allRegisters?.length / itemsPerPage);
    const windowSize = 5; // Total visible pages in the window
    const halfWindow = Math.floor(windowSize / 2);

    let startPage = Math.max(1, currentPage - halfWindow);
    let endPage = Math.min(totalPageCount, currentPage + halfWindow);

    if (currentPage <= halfWindow) {
      endPage = Math.min(windowSize, totalPageCount);
    } else if (currentPage + halfWindow > totalPageCount) {
      startPage = Math.max(1, totalPageCount - windowSize + 1);
    }

    const pages = [];
    // Conditionally add an ellipsis at the start
    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push("ellipsis");
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Conditionally add an ellipsis at the end
    if (endPage < totalPageCount) {
      if (endPage < totalPageCount - 1) pages.push("ellipsis");
      pages.push(totalPageCount);
    }

    return pages;
  };

  if (jobs.isPending || tilaclaim.isPending) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="font-bold text-2xl mb-4">{t('Claims')}</div>

      <div className="flex flex-row space-x-4 ">
        <div className="basis-auto">
          <Select onValueChange={handleValueChange}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder={t('InProgress')} />
            </SelectTrigger>
            <SelectContent ref={(ref) => {
              if (!ref) return;
              ref.ontouchstart = (e) => e.preventDefault();
              }}>
              <SelectItem value="N">{t('InProgress')}</SelectItem>
              <SelectItem value="F">{t('Finished')}</SelectItem>
              <SelectItem value="A">{t('All')}</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="basis-auto hidden md:block">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-[280px] justify-start text-left font-normal",
                  !startDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {startDate ? (
                  format(startDate, "PPP")
                ) : (
                  <span>{t('PickDate')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={startDate}
                onSelect={(date) => setStartDate(date)}
                initialFocus
                disabled={(date) =>
                  date > new Date() || date < new Date("1 march 2021")
                }
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="basis-auto hidden md:block">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-[280px] justify-start text-left font-normal",
                  !finalDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {finalDate ? (
                  format(finalDate, "PPP")
                ) : (
                  <span>{t('PickDate')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={finalDate}
                onSelect={handleFinalDateChange}
                initialFocus
                disabled={(date) => date > new Date() || date < startDate}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="basis-1/2 hidden md:block">
          <Input
            placeholder={t('Search')}
            name="search"
            value={search1}
            onChange={onChange}
          />
        </div>
      </div>

      {totalReg > 0 && (
        <div className="h-screen">
          <Table className='h-[80%]'>
            <TableHeader>
              <TableRow>
                <TableHead className="w-5/12">{t('Address')}</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="">{t('DateCreated')}</TableHead>
                {temp === '1' &&
                  <TableHead>{t('Contractor')}</TableHead>
                }
                <TableHead>{t('Action')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!currentRegisters && (
                <TableRow>
                  {t('NoClaims')}
                </TableRow>
              )}
              {currentRegisters &&
                currentRegisters?.map((claim) => (
                  <Job
                    claim={claim}
                    timeclaim={tilaclaim}
                    key={claim.id}
                    contractor_id={contractor_Id}
                  />
                ))
              }
            </TableBody>
          </Table>
          {totalReg > itemsPerPage && (
            <Pagination className="justify-end pb-10">
              <PaginationContent>
                {currentPage > 1 && (
                  <PaginationItem>
                    <PaginationPrevious
                      onClick={() =>
                        onPageChanged(Math.max(1, currentPage - 1))
                      }
                      disabled={currentPage === 1}
                    />
                  </PaginationItem>
                )}

                {getPageNumbers().map((page, index) =>
                  page === "ellipsis" ? (
                    <PaginationItem key={"ellipsis-" + index}>
                      <PaginationEllipsis />
                    </PaginationItem>
                  ) : (
                    <PaginationItem key={page}>
                      <PaginationLink
                        onClick={() => onPageChanged(page)}
                        className={currentPage === page ? "font-extrabold" : ""}
                      >
                        {page}
                      </PaginationLink>
                    </PaginationItem>
                  )
                )}

                {currentPage < totalPageCount && (
                  <PaginationItem>
                    <PaginationNext
                      onClick={() =>
                        onPageChanged(Math.min(totalPageCount, currentPage + 1))
                      }
                      disabled={currentPage === totalPageCount}
                    />
                  </PaginationItem>
                )}
              </PaginationContent>
            </Pagination>
          )}
        </div>
      )}
      {totalReg === 0 && (
      <NoResults message={t('NoClaims')} />
      )}
      <Button
        variant="ghost"
        size="icon"
        onClick={() =>
          (window.location.href = "/map/" + contractorId + "/" + status)
        }
        className="fixed bottom-4 left-4"
      >
        <MapPinned className="h-5 w-5" />
      </Button>
    </div>
  );
}
