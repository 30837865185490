import React, { useState, useEffect } from "react";
import ip from "../../config_ip";
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import "./styles.scss";
import Allfetch from "../Allfetch";

const jobId = window.location.pathname.split('/')[2]    // para[2] trae el link de authorizacion

export default function Authorization () {

  const [verify, setVerify] = useState(0)

  useEffect(() => {
    const getAuthVerify = async () => {
      Allfetch('/autverify/' + jobId, setVerify)
    }
    getAuthVerify()
  }, [])

  useEffect(() => {
    if (verify[0]?.authoc === '1') {
      window.location.href = '/linktemp/' + jobId
    }
  }, [verify])

  const updateCustom = async (emailcustom, conditions, idjob) => {
    const data = {
      emailcustom: emailcustom,
      conditions: conditions,
      idjob: idjob,
    }
    var resp = await fetch(ip.url + "/upCustom", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    resp = await resp.json()
    if (resp.data?.emailc !== null || resp.data?.emailc !== '') {
      window.location.href = '/linktemp/' + jobId
    }
  }

  return (
    <div className="container">
      <Formik
        initialValues={{
          emailcustom: "",
          conditions: false,
        }}
        validationSchema={Yup.object().shape({
          //validation for email yup 
          emailcustom: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          conditions: Yup.bool().oneOf([true, false]),
        })}
        onSubmit={(values) => {
          setTimeout(() => {
            updateCustom(
              values.emailcustom,
              values.conditions,
              values.idjob = jobId,
            );
          }, 500);
        }}
      >
      {(props) => {
        const { values, touched, errors, isSubmitting, handleSubmit, handleChange } = props
        return (
          <Form id="customer" autoComplete="off" onSubmit={handleSubmit}>
            <div className="title">
              <p>One last step</p>
            </div>
            <Form.Group>
              <Form.Label>Please provide us with your E-mail address.</Form.Label>
              <Form.Text className="text-muted">
                We will never share your emal with anyone else.
              </Form.Text>
              <Form.Control
                name="emailcustom"
                type="email"
                placeholder="E-mail"
                value={values.emailcustom}
                onChange={handleChange}
                className='email-imput'
              />
              {errors.emailcustom && touched.emailcustom && (
                <Form.Text className="text-danger">
                  {errors.emailcustom}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="check-box">
              <Form.Check
                name="conditions"
                type="checkbox"
                value={values.conditions}
                onChange={handleChange}
                className="tick"
              />
              <div>
                <Form.Label>Allow Robbie Restoration Technologies Inc. to send me emails</Form.Label>
                {errors.conditions && touched.conditions && (
                  <Form.Text className="text-danger">
                    {errors.conditions}
                  </Form.Text>
                )}
              </div>
            </Form.Group>
            <Button type="submit" disabled={isSubmitting}>Send</Button>
          </Form>
        )}}
      </Formik>
    </div>
  )
}
//, 'Accepting is required to proceed'