import ip from '../config_ip'

const Allfetch = async (url, setData, countReg = null, setData2 = null, token = null) => {
  let options
  if (token === null) {
    options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }
  } else {
    options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
  }
  try {
    const response = await fetch(ip.url + url, options)
    if (response.ok) {
      const response2 = await response.json()
      if (response2.rows) {
        setData(response2.rows)         // Datos traidos de MariaDB
      } else if (response2.data.data) {
          setData(response2.data.data) // Datos traidos de postgres
      } else {
        setData(response2.data)       // Estos son normalmente los datos de fotos
      }
      if (countReg) {
        countReg(response2.rows ? response2.rows.length : 0);
      }
      if (setData2) {
        setData2(response2.rows);
      }
    }
  } catch (error) {
    console.error("Error fetching:", error);
  }
};

export default Allfetch