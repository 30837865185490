import React, { useState, useEffect, useCallback } from 'react'
import { Page, View, Text, StyleSheet, Document, Font, Image, Link, Svg, Defs, LinearGradient, Path, Stop, PDFViewer } from '@react-pdf/renderer'
import watermark1 from './watermark1.png'
import interMedium from './Fonts/Inter-Medium.ttf'
import interItalic from './Fonts/Inter-Medium.ttf'
import interBold from './Fonts/Inter-Bold.ttf'
import interExtraBold from './Fonts/Inter-ExtraBold.ttf'
import Editjob from '../Jobs/Editjob'
import Buttonback from '../UI/Buttonback'
import { useAllFetch } from 'components/CustomHooks/useAllFetch'

const contractor_id = localStorage.getItem("contractor_id")
const jobId = window.location.pathname.split('/')[2]

let totalEnergyJob = 0.0
let totalEnergyCostJob = 0.0
let totalDH = 0
let totalBlowers = 0
let totalDryingTime = 0.0

Font.clear();
Font.register({
	family: "Inter",
	fonts: [
		{ src: interMedium, fontStyle: "normal" },
		{ src: interBold, fontStyle: "normal", fontWeight: "bold" },
		{ src: interExtraBold, fontStyle: "normal", fontWeight: "heavy" },
		{ src: interItalic, fontStyle: "italic" }
	]
})

function Certificate2() {

	const [areas, setAreas] = useState([])
	const [claimNumber, setClaimNumber] = useState("")
	const [certificate, setCertificate] = useState("")
	const [pauses, setPauses] = useState([])
	const [statusCID, setStatusCID] = useState(true)

	const certData = useAllFetch(['cerdata'], '/certificate/' + jobId)

	const destructureData = useCallback(() => {
		totalEnergyJob = 0.0
		totalEnergyCostJob = 0.0
		totalDH = 0
		totalBlowers = 0
		totalDryingTime = 0.0
		certData.data?.areas.map((area) => {
			return (
				totalDH += area.dh,
				totalBlowers += area.blowers,
				totalDryingTime += area.drying_time,
				totalEnergyJob += (area.totenergy * area.drying_time)
			)
		})
		totalEnergyCostJob = totalEnergyJob * 0.17
	}, [certData.data])

	useEffect(() => {
		setAreas(certData.data?.areas)
		setPauses(certData.data?.pauses)
		if (certData.data?.claimnumber === null || certData.data?.claimnumber === "0" || certData.data?.adjuster_id === null || certData.data?.adjuster_id === 10) {
			setStatusCID(false);
		} else {
			setStatusCID(true);
		}
		setCertificate(certData.data?.certificate);
		setClaimNumber(certData.data?.claimnumber);
		destructureData(); // Ensure you handle this correctly
}, [certData, destructureData])

	if (certData.isLoading) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

	return (
		(statusCID === true) ?
				// <body style={{ 
				// 	height: '100%',
				// 	width: '100%',
				// 	padding: 'none'
				// }}>
				<>
					<PDFViewer style={{
						position:'relative',
						height: '100vh',
						width: '100vw',
						margin: 'none',
						padding: 'none',
						border: 'none'
					}}>
						<Document>
							<Page size="LETTER" style={styles.page}>
								<View style={[styles.letterHead]} fixed>
									<View style={styles.logo}>
										<Svg width="120" height="auto" viewBox="0 0 117.39 45.41" xmlns="http://www.w3.org/2000/svg">
											<Defs>
												<LinearGradient id="myLinearGradient">
													<Stop offset="0" stopColor="#19afe6" />
													<Stop offset="1" stopColor="#64c5b8" />
												</LinearGradient>
											</Defs>
											<Path
												d="M68.22,38.39H65.31V1.48A1.71,1.71,0,0,1,65.61.3,1.71,1.71,0,0,1,66.79,0H69.7V35.2h6.74c1.13,0,1.85-.23,2.14-.71
										A4.87,4.87,0,0,0,79,32.08V16.83a4.9,4.9,0,0,0-.44-2.41c-.29-.47-1-.71-2.14-.71H71.89V12a1.94,1.94,0,0,1,.25-1.18
										c.16-.2.54-.3,1.12-.3H78.2c1.9,0,3.24.39,4,1.18 s 1.18,2.13,1.18,4V33.18c0,1.9-.4,3.24-1.18,4s-2.13,1.18-4,1.18
										h-10ZM91.44,7.27a1.73,1.73,0,0,0,.3-1.18V3.18H88.4a1.69,1.69,0,0,0-1.18.3,1.71,1.71,0,0,0-.3,1.18V7.57h3.34
										A1.71,1.71,0,0,0,91.44,7.27Zm-4,3.56A1.65,1.65,0,0,0,87.13,12V38.39H90a1.64,1.64,0,0,0,1.18-.31,1.68,1.68,0,0,0,.3-1.17
										V10.53h-2.9A1.69,1.69,0,0,0,87.44,10.83Zm19-.3h-6c-1.9,0-3.25.39-4,1.18s-1.18,2.13-1.18,4V33.18c0,1.9.39,3.24,1.18,4
										s2.13,1.18,4,1.18h8.28c.58,0,1-.1,1.12-.31a1.91,1.91,0,0,0,.25-1.17V35.2h-7.9c-1.13,0-1.84-.23-2.14-.71
										a 4.89,4.89,0,0,1-.43-2.41V16.83a4.34,4.34,0,0,1,.49-2.49c.33-.42,1.1-.63,2.3-.63h2.36a3.15,3.15,0,0,1,2.25.58,3.41,3.41,0,0,1,.55,2.27
										v4.22a4.91,4.91,0,0,1-.44,2.42c-.29.47-1,.71-2.14.71h-3.18v1.7a1.94,1.94,0,0,0,.25,1.18c.16.2.53.3,1.12.3h3.29
										c1.9,0,3.24-.39,4-1.18s1.18-2.13,1.18-4V15.74c0-1.9-.39-3.25-1.18-4S108.39,10.53,106.49,10.53Zm10.6,30.36a1.69,1.69,0,0,0-1.18-.3
										H113v3.34a1.19,1.19,0,0,0,1.48,1.48h2.9V42.07A1.69,1.69,0,0,0,117.09,40.89Zm-7.34.22a1.73,1.73,0,0,0-1.18-.3
										H18.84L13.93,24.63a4.4,4.4,0,0,0,3.18-1.4A6.07,6.07,0,0,0,18,19.47V9.82c0-1.9-.39-3.24-1.18-4s-2.13-1.18-4-1.18
										H1.48A1.7,1.7,0,0,0,.3,4.92,1.68,1.68,0,0,0,0,6.09V43H2.91a1.73,1.73,0,0,0,1.18-.3,1.71,1.71,0,0,0,.3-1.18
										V7.79h6.69c1.13,0,1.84.24,2.14.72a4.89,4.89,0,0,1,.43,2.41v7.46a4.89,4.89,0,0,1-.43,2.41c-.3.48-1,.71-2.14.71
										H6.58v1.7a1.92,1.92,0,0,0,.25,1.18c.16.2.54.3,1.12.3H9.6l5.8,19a2.26,2.26,0,0,0,.68,1.18,2.23,2.23,0,0,0,1.29.3h92.68
										v-2.9A1.69,1.69,0,0,0,109.75,41.11Zm-87-3.9c-.79-.79-1.18-2.13-1.18-4V15.74c0-1.9.39-3.25,1.18-4s2.13-1.18,4-1.18
										h7.68c1.9,0,3.24.39,4,1.18s1.18,2.13,1.18,4V33.18c0,1.9-.4,3.24-1.18,4s-2.13,1.18-4,1.18H26.76C24.86,38.39,23.51,38,22.73,37.21
										Zm3.21-5.13a4.76,4.76,0,0,0,.44,2.41c.29.48,1,.71,2.13.71h4.17c1.13,0,1.85-.23,2.14-.71a4.87,4.87,0,0,0,.44-2.41
										V16.83a4.9,4.9,0,0,0-.44-2.41c-.29-.47-1-.71-2.14-.71H28.51c-1.13,0-1.84.24-2.13.71a4.78,4.78,0,0,0-.44,2.41
										Zm20.52,6.3h9.86c1.9,0,3.24-.4,4-1.18s1.18-2.13,1.18-4V15.74c0-1.9-.4-3.25-1.18-4s-2.13-1.18-4-1.18H51.38c-.58,0-1,.1-1.12.3
										A1.94,1.94,0,0,0,50,12v1.7h4.55c1.13,0,1.85.24,2.14.71a4.9,4.9,0,0,1,.44,2.41V32.08a4.87,4.87,0,0,1-.44,2.41c-.29.48-1,.71-2.14.71
										H47.82V0H44.91a1.71,1.71,0,0,0-1.18.3,1.71,1.71,0,0,0-.3,1.18V38.39h3Z"
												fill="url('#myLinearGradient')"
											/>
										</Svg>
									</View>
									<View style={styles.claimInfo} fixed>
										<Text style={styles.blueHeader} fixed>DRYING CERTIFICATE FOR:</Text>
										<Text style={styles.claimHeader} fixed>Claim #: {claimNumber}</Text>
										<Text style={styles.subtitleh} fixed>{certData.data?.address.split(',')[0]}</Text>
									</View>
								</View>
								<View style={styles.Certificate2}>
									{/* <View style={styles.generalData} /> */}
									<View style={[styles.tableTitle, styles.blueHeader]}>
										<Text >SUMMARY</Text>
									</View>
									{/* Aqui se disena la primera tabla */}
									<View style={styles.overallTable}>
										<View style={styles.tablehead}>
											<Text style={styles.setup}>CLAIM SETUP</Text>
											<Text style={styles.time}>TIME ESTIMATION</Text>
											<Text style={styles.energy}>ENERGY USED</Text>
										</View>
										<View style={styles.tablecontent}>
											<Text style={styles.setup}>{certData.data?.areas.length} Areas</Text>
											<Text style={styles.time}>{parseFloat(totalDryingTime).toFixed(2)}h Drying Time Net</Text>
											<Text style={styles.energy}>CAD ${ isNaN(totalEnergyCostJob) ? '0.00' : parseFloat(totalEnergyCostJob).toFixed(2)}</Text>
										</View>
										<View style={styles.tablecontent}>
											<Text style={[styles.subtitle, styles.setup]}>{totalDH} Dehumidifier - {totalBlowers} Air Mover </Text>
											<Text style={[styles.subtitle, styles.time, { width: "150px" }]}></Text>
											<Text style={[styles.subtitle, styles.energy]}>{isNaN(totalEnergyJob) ? '0.00 Kwh' : parseFloat(totalEnergyJob).toFixed(2)}kWh</Text>
										</View>
									</View>
									{/* Aqui finaliza el diseno de la primera tabla */}
									{/* Aqui se disena la segunda tabla */}
									<View style={styles.generalData} />
									<View style={[styles.tableTitle, styles.blueHeader]}>
										<Text>BREAKDOWN</Text>
									</View>
									<View style={styles.overallTable}>
										<View style={styles.tablehead}>
											<Text style={styles.setup}>AREA SETUP</Text>
											<Text style={styles.time}>TIME BREAKDOWN</Text>
											<Text style={styles.energy}>ENERGY USED</Text>
										</View>
									</View>

									{areas?.map((area, index) => (
										<div key={index}>
											<View style={styles.overallTable}>
												<View style={styles.tablecontent}>
													<Text style={styles.setup}>{area.name}</Text>
													{area.drying_time ? <Text style={styles.time}>{parseFloat(area.drying_time).toFixed(2)}h Drying</Text> : <Text style={styles.time}>0.0h Drying</Text>}
													<Text style={styles.energy}>CAD ${isNaN(area.totenergy) ? '0.00' : parseFloat(area.totenergy * area.drying_time * 0.17).toFixed(2)}</Text>
												</View>
												<View style={styles.tablecontent}>
													<Text style={[styles.subtitle, styles.setup]}>{area.dh} {area.typedh} - {area.blowers} {area.typebl} </Text>
													<Text style={[styles.subtitle, styles.time]}>Pre-drying: {isNaN(area.totalElapsedTimeA) ? '0.00h' : parseFloat(area.totalElapsedTimeA - area.drying_time).toFixed(2) < 0 ? 0 : parseFloat(area.totalElapsedTimeA - area.drying_time).toFixed(2)}h - Total: {isNaN(area.totalElapsedTimeA) ? '0.00h' : parseFloat(area.totalElapsedTimeA).toFixed(2)}h</Text>
													<Text style={[styles.subtitle, styles.energy]}>{isNaN(area.totenergy) ? '0.00kWh': parseFloat(area.totenergy * area.drying_time).toFixed(2)}kWh</Text>
												</View>
											</View>

											{pauses ? pauses?.map((pause, index2) => (
												pause.id === area.area ?
													<View key={pause.id} style={{ flexDirection: "row", width: "100%", marginBottom: "5px" }}>
														<View style={{ width: "50%" }}></View>
														<View style={{ flexDirection: "column", width: "29%", textAlign: "left" }}>
															<Text style={styles.pausename}>Paused at: {`${pause.created_at.substring(0, 10)} ${pause.created_at.substring(11, 16)} UTC`.replaceAll('-', '/')}</Text>
															<Text style={styles.pausereason}>{pause.why}</Text>
														</View>
														<View style={{ width: "20%" }}></View>
													</View>
													:
													null
											)): null}
										</div>
									))}
									{/* Aqui finaliza el diseno de la segunda tabla */}
								</View>
								<View style={[styles.footer, { flexDirection: "column", height: "auto" }]} fixed>
									<Text style={styles.certnumber}>Robbie Certificate Number: {certificate}</Text>
									<Link style={styles.link} src="https://robbiert.com">www.robbiert.com </Link>
									<Text style={styles.brand}>Robbie Restoration Technologies Inc. </Text>
									<Text style={styles.address}>1040 Martin Grove Rd Etobicoke, ON, Cananda. </Text>
								</View>
								<Image src={watermark1} style={styles.watermark} alt='' fixed={true} />
								<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
									`${pageNumber} / ${totalPages}`
								)} fixed />
							</Page>
						</Document>
					</PDFViewer>
					<div style={{ 
							position:	'absolute',
							top: '7rem',
							right: '10rem',
							margin: 'none',
							padding: 'none',
							border: 'none',
							color: '#00FF00', 
						}}>
						<Buttonback url={"/jobs/" + contractor_id} />
					</div>
			</>		
			// </body>
			:
			<Editjob ronly={true} />
	)
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: "2cm",
		fontFamily: "Inter",
		fontSize: "12px",
	},
	Certificate2: {
		left: "2cm",
		width: "175.9mm",
		flexDirection: "column",
		alignItems: "center",
		textAlign: "center",
	},

	blueHeader: {
		fontWeight: "heavy",
		letterSpacing: "0.4px",
		textTransform: "uppercase",
		color: "#17aee5",
	},

	letterHead: {
		paddingTop: "2cm",
		paddingLeft: "2cm",
		paddingRight: "2cm",
		paddingBottom: "5mm",
		display: "flex",
		justifyContent: "spaceBetween",
		height: "auto",
		flexDirection: "row",
	},

	claimInfo: {
		alignSelf: "flex-end",
	},

	claimHeader: {
		fontWeight: "bold",
		alignSelf: "flex-end",
	},

	subtitleh: {
		fontSize: "8px",
		alignSelf: "flex-end",
		color: "#C4C4C4",
	},

	subtitle: {
		fontSize: "8px",
		textAlign: "left",
		color: "#C4C4C4",
	},

	logo: {
		width: "320px",
		height: "auto",
	},

	generalData: {
		marginBottom: "5mm",
	},

	tableTitle: {
		borderTop: 0,
		width: "100%",
		textAlign: "left",
		marginBottom: "1mm",
	},

	setup: {
		width: "50%",
	},

	time: {
		width: "30%",
	},

	energy: {
		width: "15%",
	},

	certnumber: {
		fontSize: "9px",
	},

	link: {
		fontSize: "9px",
		color: "#17aee5",
	},

	brand: {
		fontSize: "9px",
		fontWeight: "bold",
	},

	address: {
		fontSize: "9px",
		color: "#C4C4C4",
	},

	footer: {
		position: "absolute",
		fonrSize: "9px",
		bottom: "1cm",
		left: "1cm",
		textAlign: "left",
		alignSelf: "flex-start",
	},

	watermark: {
		position: "absolute",
		right: 0,
		bottom: 0,
		width: "12cm",
		height: "auto",
	},

	pageNumber: {
		position: 'absolute',
		fontSize: "9px",
		bottom: "1cm",
		right: "1cm",
		color: '#17aee5',
	},

	tablehead: {
		fontWeight: "bold",
		flexDirection: "row",
		fontSize: "10px",
		marginBottom: "1mm",
		borderBottom: "0.1mm",
		borderBottomColor: "#C4C4C4"
	},

	tablecontent: {
		width: "100%",
		flexDirection: "row",
		fontSize: "10px",
	},

	overallTable: {
		width: "100%",
		textAlign: "left",
		height: "auto",
		marginBottom: "1mm"
	},

	pausename: {
		fontSize: "8px",
		fontStyle: "italic",
	},

	pausereason: {
		fontSize: "6px",
		fontStyle: "italic",
	},

});

export default Certificate2
