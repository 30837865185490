import React from 'react';
import PropTypes from 'prop-types';
import { RobbieButtonStyled } from './styles';


export const RobbieButton = (props) => {
  const {
    type,
    href,
    icon,
  } = props;

  return (
    <RobbieButtonStyled  {...props}
    type={type}
    href={href}
    >
    <img src={icon} alt=""/> 
    </RobbieButtonStyled>
    
  );
};

RobbieButton.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
};

RobbieButton.defaultParams = {
  type: 'active',
  onClick: () => {},
  href: '',
  icon: '',
};
