import React from "react";
import { Button } from "@/components/ui/button.jsx";
import { TableRow, TableCell } from "@/components/ui/table.jsx";
import { 
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu.jsx";
import { MoreHorizontal } from "lucide-react";

export default function User (props) {

  const { user } = props;

  return (
    <TableRow align="left">
      <TableCell className="p-0">{user.id}</TableCell>
      <TableCell className="p-0">{user.fullname}</TableCell>
      <TableCell className="p-0">{user.username}</TableCell>
      <TableCell className="p-0">{user.name}</TableCell>
      <TableCell className="p-0">{user.role}</TableCell>
      <TableCell className="p-0">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0 ">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end'>
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => window.location.href="/euser/" + user.id}>Edit User</DropdownMenuItem>
          <DropdownMenuItem onClick={() => window.location.href="/adduser"}>Create User</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      </TableCell>
    </TableRow>
  )
}
