import React from "react";
import "../linechart.scss";
import Plot from "react-plotly.js";
import Viewbox from "../UI/Viewbox";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent
} from '@/components/ui/tabs.jsx';
import { Spinner } from 'react-bootstrap'
import Telemetrics from "../UI/AreaTelemetrics";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import Buttonback from "../UI/Buttonback";

import { Loading } from "../UI/Loading";

const arraySlash = window.location.pathname.split('/')
const jobId = arraySlash[2]
const areaId = arraySlash[3]

export default function LinegraphPub(props) {

  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };

  const area = useAllFetch(['area'], '/getareaPub/' + areaId)
  const events = useAllFetch(['events'], '/geteventsPub/' + areaId)
  const daily = useAllFetch(['daily'], '/dailyPub/' + jobId + '/' + areaId)
  const current = useAllFetch(['current'], '/currentPub/' + jobId + '/' + areaId)
  const lcountxarea = useAllFetch(['lcountxarea'], '/lcountxareaPub/' + areaId)
  const pictures = useAllFetch(['pictures'], '/getPicturesPub/' + jobId + '/' + areaId)

  let humidata = [];
  let tempdata = [];
  let datedata = [];
  let amperdata = [];
  let powerdata = [];
  let energydata = [];
  let moisturedata = [];

  let timeelapsed = 0;

  let currenttemp = 0;
  let currenthumi = 0;
  let currentamper = 0;
  let currentpower = 0;
  let currentenergy = 0;

  let currentdate = "";
  let firstdate = [];
  let lastdate = [];
  let des = [];
  let setpoint = 0.0;

  let RH = 0;
  let t = 0;
  let H = 0;
  let dewpoint = 0;
  let pausedate = [];
  let pausewhy = [];

  // // Se busca dinamicamente el parametro id
  if (area !== undefined) {
    let descriptions = area.data?.[0].jobtemp
    if (descriptions !== undefined) {
      descriptions.forEach((element) => {
        descriptions = descriptions.slice(0)
        des = element
        setpoint = des.humisetpoint
        //console.log (des)
      })
    }
  }

  if (daily.data !== undefined) {
    const data = daily.data
    for (let i = 0; i < Object.keys(data).length; i = i + 1) {
      if (data !== undefined) {
        var fechaLocal = new Date(data[i].date)
        datedata.push(fechaLocal);
        humidata.push(data[i].humi);
        tempdata.push(data[i].temp);
        amperdata.push(data[i].amper_ef);
        powerdata.push(data[i].power / 1000);
        energydata.push(data[i].energy);
        moisturedata.push(data[i].moisture);
      }
      lastdate = new Date(data[data.length - 1].date);
      firstdate = new Date(data[0].date);
    }
    if (events !== undefined) {
      for (let j = 0; j < events.data?.length; j = j + 1) {
        if (events.data?.[j].why !== 'Restart area') {
          pausedate.push(new Date(events.data?.[j].created_at));
          pausewhy.push(events.data?.[j].why)
        }
      }
    }
  }

  if (current !== undefined) {
    const data1 = current.data
    if (data1 !== undefined) {
      currenttemp = data1.temp;
      currenthumi = data1.humi;
      currentamper = data1.amper_ef.toFixed(2);
      currentenergy = data1.energy.toFixed(2);
      currentpower = data1.power.toFixed(2);
      currentdate = new Date(Date.parse(data1.date)).toLocaleString('en-US', options);
      RH = currenthumi
      t = currenttemp
      H = (Math.log10(RH) - 2.0) / 0.4343 + (17.62 * t) / (243.12 + t);
      dewpoint = (243.12 * H / (17.62 - H)).toFixed(2);
    }
  }

  timeelapsed = timeConversion(Date.parse(lastdate) - Date.parse(firstdate))

  function timeConversion(millisec) {
    //var seconds = (millisec / 1000).toFixed(1);
    var minutes = (millisec / (1000 * 60.0)).toFixed(3);
    var hours = (millisec / (1000 * 60.0 * 60.0)).toFixed(3);
    var days = (millisec / (1000 * 60.0 * 60.0 * 24)).toFixed(3);

    let realdays = 0
    let realhours = 0
    let realminutes = 0
    if (days > 1) {
      realdays = days.toString().split('.')[0];
      hours = days.toString().split('.')[1] * 2.4 / 100
    }
    if (hours < 24) {
      realhours = hours.toString().split('.')[0];
      hours = parseFloat(hours).toFixed(3)
      minutes = hours.toString().split('.')[1] * 6.0 / 100
    }
    if (minutes < 60) {
      realminutes = minutes.toString().split('.')[0];
    }
    if (realdays < 1) {
      return realhours + " Hours " + realminutes + " Minutes"
    } else {
      return realdays + " Days " + realhours + " Hours " + realminutes + " Minutes"
    }
  }

  const pause_tracesh = []
  const humidity_traces = [
    {
      name: "Relative Humidity (%)",

      x: datedata,
      y: humidata,
      mode: "lines",
      line: { shape: "spline" },
      type: "scatter",
      smoothing: 1.3,
      marker: { color: "#54DED0" },
    },

    {
      name: "Relative Humidity Shadow",
      showlegend: false,
      x: datedata,
      y: humidata,
      mode: "lines",
      line: { shape: "spline", width: 10 },
      type: "scatter",
      smoothing: 1.3,
      marker: { color: "#54ded033" },
    },


    {
      name: "Temperature (°C)",
      x: datedata,
      y: tempdata,
      mode: "lines",
      line: { shape: "spline" },
      type: "scatter",
      smoothing: 1.3,
      marker: { color: "#ff76004f" },
    },

    {
      name: "Temperature Shadow(°C)",
      showlegend: false,
      x: datedata,
      y: tempdata,
      mode: "lines",
      line: { shape: "spline", width: 10 },
      type: "scatter",
      smoothing: 1.3,
      marker: { color: "#ff760014" },
    },
    {
      name: "Setpoint (%)",
      x: [firstdate, lastdate],
      y: [setpoint, setpoint],
      mode: "lines",
      type: "scatter",
      line: {
        dash: "dot",
        width: 1,
      },
      marker: { color: "gray" }
    },
    {
      name: "Electrical Current (A)",
      x: datedata,
      y: amperdata,
      mode: "lines",
      line: { shape: "linear" },
      smoothing: 1.3,
      type: "scatter",
      marker: { color: "#F2D027" },
    },
    {
      name: "Electrical Current Shadow (A)",
      showlegend: false,
      x: datedata,
      y: amperdata,
      mode: "lines",
      line: { shape: "linear", width: 10 },
      smoothing: 1.3,
      type: "scatter",
      marker: { color: "#f2d02742" },
    }

  ]

  for (let i = 0; i < pausedate.length; i++) {
    pause_tracesh.push(
      {
        name: "Paused",
        x: [pausedate[i], pausedate[i]],
        y: [1, 80],
        mode: "lines+markers",
        line: {
          dash: "dot",
          width: 2,
        },
        type: "scatter",
        marker: { color: "black" },
        text: pausewhy[i],
        textposition: "middle right",
      }
    )
  }

  humidity_traces.push(...pause_tracesh)

  if ( area.isLoading || events.isLoading || daily.isLoading || lcountxarea.isLoading || pictures.isLoading) {
    return <Loading />;  }

  return (
    <div className="container">
      <div>
        {jobId.length === 64 ?
          <Buttonback url={"/linktemp/" + jobId} />
          :
          <Buttonback url={"/lareas/" + jobId} />
        }
        <div>
          <p className="area-title">{area.data?.[0].aname}</p>
        </div>
      </div>
      <Tabs defaultValue="monitoring">
        <TabsList>
          <TabsTrigger value="monitoring">MONITORING</TabsTrigger>
          <TabsTrigger value="documents">DOCUMENTATION</TabsTrigger>
        </TabsList>
        <TabsContent value="monitoring">
          <div className="tab-container">
            <div className="plot-1">

              <Plot
                data={humidity_traces}
                useResizeHandler
                style={{ width: "100%", height: "100%" }}
                layout={{
                  margin:
                {
                  l: 50,
                  r: 60,
                  b: 50,
                  t: 50,
                  pad: 4
                },
                  
                  yaxis: { range: [0, 100], color: '#9E9E9E' },
                  xaxis: {
                    autorange: true,
                    color: '#9E9E9E'
                  },
                  showlegend: true,
                  legend: {
                    bgcolor: 'rgba(0,0,0,0)',
                    itemclick: false,
                    itemdoubleclick: false,
                    x: 1,
                    xanchor: 'right',
                    y: 1,
                    font: {
                      size: 12,
                    }
                  },
                }}
              />
            </div>
            <div className="text-muted-foreground mb-2">
              Blowers: {lcountxarea.data?.blowers} | Dehumidifiers: {lcountxarea.data?.dh} | Description: {area.data?.[0].description} 
            </div>
            <Telemetrics
              current_humidity={currenthumi}
              current_temperature={currenttemp}
              current_dewpoint={parseFloat(dewpoint)}
              current_amperage={parseFloat(currentamper)}
              current_power={parseFloat(currentpower)}
              current_energy={parseFloat(currentenergy)}
              timeelapsed={timeelapsed}
              last_date_updated={currentdate}
            />

          </div>

        </TabsContent>
        <TabsContent value="documents">
          {!pictures.isLoading ?
            <>
              <div className="claim-pictures">
                {pictures.data?.length > 0 ? (
                  pictures.data.map((file) => (
                    <Viewbox
                      key={file.filename}
                      file={file}
                      jobid={jobId}
                    />
                  ))
                ) : (
                  <p>No area pictures available.</p>
                )}
              </div>
            </>
            :
            <div className="flex-div">
              <Spinner animation="border" variant="info" />
              <div>
                <h3>Loading file ...</h3>
              </div>
            </div>
          }
        </TabsContent>
      </Tabs>
    </div>
  )
}
