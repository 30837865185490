import React from "react";
import ip from "../../config_ip"
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button.jsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaSave } from "react-icons/fa";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

import { Loading } from "../UI/Loading";

const robbie_id = window.location.pathname.split('/')[2];
const contractor_id = localStorage.getItem('temp')

export default function EditRobbie (props) {

	const { getToken } = useAuth()

	const robbie = useAllFetch(['robbie'], '/getRobbie/' + robbie_id)

	const mutation = useMutation({
    mutationFn: async (data) => {
      const tokenu = await getToken()
      const response = await fetch(ip.url + '/updateRobbie/' + robbie_id, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )
	
	const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = '/lrobbies'
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
	}
	
	const editRobbieSchema = object().shape({
		namerobbie: string()
			.max(8, "Maximo 8 caracteres"),
		sensor: string(),
	})

	const form = useForm({
		resolver: yupResolver(editRobbieSchema),
		values: {
			id: robbie.data?.id,
			name_raspi: robbie.data?.name_raspi,
			sensor: robbie.data?.sensor,
			status: robbie.data?.status,
			moisture: robbie.data?.moisture,
			usemoisture: robbie.data?.usemoisture,
		},
	})

	if (robbie.isPending) {
		return <Loading />;	}

	return (
		<div className="container">
			<Breadcrumb>
				<BreadcrumbList>
					<BreadcrumbItem>
						<BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbSeparator />
					<BreadcrumbItem>
						<BreadcrumbLink href={"/lRobbies"}>List Robbies</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbSeparator />
					<BreadcrumbItem>
						<BreadcrumbLink href={window.location.href}>Edit Robbie</BreadcrumbLink>
					</BreadcrumbItem>
				</BreadcrumbList>
			</Breadcrumb>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<FormField
						control={form.control}
						name="name_raspi"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Name</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter Robbie Name"
										{...field}
										{...form.register("name_raspi")}
										value={field.value}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="sensor"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Sensor</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter Sensor Name"
										{...field}
										{...form.register("sensor")}
										value={field.value}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField control={form.control}
						name="status"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Status</FormLabel>
								<FormControl>
									<Select
										onValueChange={field.onChange}
										value={field.value}
									>
										<SelectTrigger>
											<SelectValue placeholder='Select Status' />
										</SelectTrigger>
										<SelectContent>
												<SelectItem value="A">Active</SelectItem>
												<SelectItem value="I">Inactive</SelectItem>
										</SelectContent>
									</Select>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField control={form.control}
						name="moisture"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Has Moisture Sensor?</FormLabel>
								<FormControl>
									<Select
										value={field.value}
										onValueChange={field.onChange}
									>
										<SelectTrigger>
											<SelectValue placeholder='Select Answer' />
										</SelectTrigger>
										<SelectContent>
												<SelectItem value="Y">Yes</SelectItem>
												<SelectItem value="N">No</SelectItem>
										</SelectContent>
									</Select>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField control={form.control}
						name="usemoisture"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Use Moisture Sensor?</FormLabel>
								<FormControl>
									<Select
										value={field.value}
										onValueChange={field.onChange}
									>
										<SelectTrigger>
											<SelectValue placeholder='Select Answer' />
										</SelectTrigger>
										<SelectContent>
												<SelectItem value="Y">Yes</SelectItem>
												<SelectItem value="N">No</SelectItem>
										</SelectContent>
									</Select>
								</FormControl>
							</FormItem>
						)}
					/>
					<Button type="submit">
							<FaSave></FaSave>
					</Button>
				</form>
			</Form>
		</div>
	)
}
