import React from 'react';
import Adjuster from './Adjuster';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead } from '@/components/ui/table.jsx';
  import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
  } from "@/components/ui/breadcrumb.jsx";
import { useAllFetch } from '../CustomHooks/useAllFetch';

import { Loading } from "../UI/Loading";

const contractor_id = localStorage.getItem('temp')

export default function Adjusters () {

  const adjusters = useAllFetch(['adjusters'], '/adjusters')

  if (adjusters.isPending) {
    return <Loading />;  }

  if (adjusters.data?.length !== 0) {
    return (
      <div className='container'>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={window.location.href}>List Adjusters</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="title-2 mt-2 ml-3">
          Adjusters
        </div>
        <Table responsive="true" striped="true" size="sm" className="mt-2 mr-2 ml-3" style={{width: '98%'}}>
          <TableHeader align="left">
            <TableRow>
              <TableHead></TableHead>
              <TableHead>NAME</TableHead>
              <TableHead>PHONE</TableHead>
              <TableHead>USERNAME</TableHead>
              <TableHead>INSURANCE</TableHead>
              <TableHead>ROLE</TableHead>
              <TableHead>CONTRACTOR</TableHead>
              <TableHead>ACTIONS</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            { adjusters.data?.map(adjuster => <Adjuster adjuster={adjuster} key={adjuster.id} />) }
          </TableBody>            
        </Table>
      </div>
    )
  } else {
    return (
      <div>
        <h3>There is not adjusters created</h3>
      </div> )
  }
}
