import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLang from './i18n/locales/en/en.json';
import frLang from './i18n/locales/fr/fr.json';
import esLang from './i18n/locales/es/es.json';

const resources = {
  en: {
    translation: enLang,
  },
  fr: {
    translation: frLang,
  },
  es: {
    translation: esLang,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: navigator.language.split('-',1)[0], // No observe ningun resultado. Es posible que haga falta la traduccion al español
    // lng: localStorage.getItem("language"),  //"fr", // Esta variable debe ser dinamica, para poder que el usuario elija el idioma
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;