import React, { useState } from "react";
import ip from "../../config_ip"
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button.jsx';
import { Input } from "@/components/ui/input.jsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb.jsx";
import { FaSave } from "react-icons/fa";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useAuth } from "@clerk/clerk-react"

const contractor_id = localStorage.getItem('contractor_id')

export default function ContractorForm() {

  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)
  const [zipCode, setzipCode] = useState('')
  const [address2, setAddress2] = useState('')
  const { getToken } = useAuth()

  const handleChangeA = address => {
    setAddress2(address)
  }

  const handleSelectA = async address => {
    setAddress2(address)
    const results = await geocodeByAddress(address)
    let zcode = results[0].address_components[results[0].address_components.length - 1].long_name.replace(' ', '')
    setzipCode(zcode)
    const latLng = await getLatLng(results[0])
    setLat(latLng.lat)
    setLng(latLng.lng)
  }

  const searchOptions = {
    componentRestrictions: { country: ["ca", "us"] }
  }

  const onSubmit = async (data) => {
    const tokenu = await getToken()
    await fetch(ip.url + "/addcontractor", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenu}`
      },
    })
    window.location.pathname = "/lcontractors";
  }

  const contractorFormSchema = object().shape({
    name: string(),
    address: string(),
    email: string(),
    phone: string(),
    contact: string(),
    status: string(),
    logo: string()
  })
  .required()

  const form = useForm({
    resolver: yupResolver(contractorFormSchema),
    values: {
      address: address2,
      status: "A",
      lat,
      lng,
      zipcode: zipCode,
    },
  })

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={"/lcontractors"}>List Contractors</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Create Contractor</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <PlacesAutocomplete
            value={address2}
            onChange={handleChangeA}
            onSelect={handleSelectA}
            searchOptions={searchOptions}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div >
                <input
                  {...getInputProps({
                    placeholder: 'Search Address ...',
                    className: 'location-search-input',
                  })}
                  autoFocus={true}
                />

                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading ...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active ? 'suggestion-item-active' : 'suggestion-item'
                    const style = suggestion.active ? { backgroundColor: '#65bdb1', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' }
                    return (
                      <div {...getSuggestionItemProps(suggestion, { className, style })} key={suggestion.index}>
                        <span>{suggestion.description}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name Contractor</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Write a name contractor"
                    {...field}
                    {...form.register("name")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            type="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Write an Email"
                    {...field}
                    {...form.register("email")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Write a phone"
                    {...field}
                    {...form.register("phone")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="contact"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Contact</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Write a contact"
                    {...field}
                    {...form.register("contact")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Status</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder='Select status' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="A">Active</SelectItem>
                      <SelectItem value="I">Inactive</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="logo"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Logo</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Write a logo"
                    {...field}
                    {...form.register("logo")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Button type="submit">
            <FaSave></FaSave>
          </Button>
        </form>
      </Form>
    </div>
  )
}
