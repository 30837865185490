import React from 'react'
import { Button } from '@/components/ui/button.jsx';
import {
  TableRow,
  TableCell,
} from "@/components/ui/table.jsx";
import { 
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu.jsx";
import { MoreHorizontal } from 'lucide-react';

export default function Param(props) {

  const { param } = props

  return (
    <TableRow align="left">
      <TableCell className="p-0">{param.id}</TableCell>
      <TableCell className="p-0">{param.hst}</TableCell>
      <TableCell className="p-0">{param.hai}</TableCell>
      <TableCell className="p-0">{param.year}</TableCell>
      <TableCell className="p-0">{param.month}</TableCell>
      <TableCell className="p-0">{param.day}</TableCell>
      <TableCell className="p-0">{param.offpeak}</TableCell>
      <TableCell className="p-0">{param.midpeak}</TableCell>
      <TableCell className="p-0">{param.onpeak}</TableCell>
      <TableCell className="p-0">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0 ">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => window.location.href="/eParams/" + param.id}>Edit Params</DropdownMenuItem>
            <DropdownMenuItem onClick={() => window.location.href="/addParams"}>Create Params</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  )
}
