import React from "react";
import { Button } from "@/components/ui/button.jsx";
import {
  TableRow,
  TableCell,
} from "@/components/ui/table.jsx";
import { 
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu.jsx";
import { MoreHorizontal } from "lucide-react";

export default function Equipment (props) {

    const { equipment } = props;

    return (
      <TableRow align="left">
        <TableCell className="p-0">{equipment.name}</TableCell>
        <TableCell className="p-0">{equipment.type}</TableCell>
        <TableCell className="p-0" align="center">{equipment.hourly_cad}</TableCell>
        <TableCell className="p-0" align="center">{equipment.amperage}</TableCell>
        <TableCell className="p-0" align="left">{equipment.xactimate_code}</TableCell>
        <TableCell className="p-0" align="center">{equipment.active === true ? 'Active' : 'Inactive'}</TableCell>
        <TableCell className="p-0">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0 ">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => window.location.href="/eEquipment/" + equipment.id}>Edit Equipment</DropdownMenuItem>
            <DropdownMenuItem onClick={() => window.location.href="/addEquipment"}>Create Equipment</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        </TableCell>
      </TableRow>
    );
}
