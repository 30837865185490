import React from "react";
import { useForm } from "react-hook-form";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { FaSave } from "react-icons/fa";
import ip from "../../config_ip";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

import { Loading } from "../UI/Loading";

const contractor_id = localStorage.getItem('temp')

export default function AdjusterForm () {
  
  const { getToken } = useAuth()

  const contractors = useAllFetch(['contractors'], '/contractors')
  const insurances = useAllFetch(['insurances'], '/insurances')

  const mutation = useMutation({
    mutationFn: async (data) => {
      const tokenu = await getToken()
      const response = await fetch(ip.url + '/addAdjuster', {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        }
      })
      return response.json()
      }
    }
  )

  const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = 'ladjuster'
        },
        onError: function () {
          console.log("Request Error")
        }
      }
    )
  };

  const adjusterFormSchema = object().shape({
    aname: string(),
    aphone: string()
      .min(7, "Too Short!")
      .max(11, "Too Long!"),
    username: string()
      .min(3, "Too Short!")
      .max(50, "Too Long!"),
    insurco_id: number(),
    password: string(),
    contractor_id: number(),
    role: string(),
  })
  .required()

  const form = useForm({
    resolver: yupResolver(adjusterFormSchema),
    values: {
      aname: "",
      aphone: "",
      username: "",
      insurco_id: 0,
      password: "",
      contractor_id: 0,
      role: "member",
    },
  })

  if (contractors.isLoading || insurances.isLoading) {
    return <Loading />;  }

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={"/ladjuster"}>List Adjusters</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Create Adjuster</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="aname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Adjuster Name</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter adjuster name"
                  {...field}
                  {...form.register("aname")}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="aphone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Adjuster Phone</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter adjuster phone"
                  {...field}
                  {...form.register("aphone")}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          type="email"
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Adjuster User Name</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your email"
                  {...field}
                  {...form.register("username")}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField control={form.control}
          name="insurco_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Insurance Name</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                >
                  <SelectTrigger>
                    <SelectValue placeholder='Select Insurance Name' />
                  </SelectTrigger>
                  <SelectContent>
                    {insurances.data.map((insurance) => (
                      <SelectItem
                        key={insurance.id}
                        value={insurance.id.toString()}
                      >
                        {insurance.insurancename}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          type="password"
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Adjuster Password</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter adjuster password"
                  {...field}
                  {...form.register("password")}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField control={form.control}
          name="contractor_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contractor Name</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                >
                  <SelectTrigger>
                    <SelectValue placeholder='Select Contractor Name' />
                  </SelectTrigger>
                  <SelectContent>
                    {contractors.data.map((contractor) => (
                      <SelectItem
                        key={contractor.id}
                        value={contractor.id.toString()}
                      >
                        {contractor.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  value={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder='Select a Role' />
                  </SelectTrigger>
                  <SelectContent>
                      <SelectItem value="manager">Manager</SelectItem>
                      <SelectItem value="member">Member</SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
        <Button type="submit">
          <FaSave></FaSave>
        </Button>
        </form>
      </Form>
    </div>
  )
}
