import React from 'react'
import './styles.scss'

export default function Buttonback(props) {
  const { url } = props

  const handleClick = () => {
    window.location.pathname = url
  }

  return (
    <div className='backlink' onClick={handleClick} >
      &lt; Back
    </div>
  )
}
