import React from "react";
import { Button } from "@/components/ui/button.jsx";
import {
  TableRow,
  TableCell
} from "@/components/ui/table.jsx";
import { 
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu.jsx";
import { MoreHorizontal } from "lucide-react";

export default function Robbie (props) {

  const { robbie } = props;

  return (
    <TableRow align="left">
      <TableCell className="p-0">{robbie.name_raspi}</TableCell>
      <TableCell className="p-0">{robbie.sensor}</TableCell>
      <TableCell className="p-0">{robbie.status}</TableCell>
      <TableCell className="p-0">{robbie.moisture === 'N' ? 'No' : 'Yes'}</TableCell>
      <TableCell className="p-0">{robbie.usemoisture === 'N' ? 'No' : 'Yes'}</TableCell>
      <TableCell className="p-0">{robbie.is_ws_connected === false ? 'No' : 'Yes'}</TableCell>
      <TableCell className="p-0">{robbie.is_sim_connected === false ? 'No' : 'Yes'}</TableCell>
      <TableCell className="p-0">{robbie.ws_sid}</TableCell>
      <TableCell className="p-0">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0 ">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => window.location.href="/eRobbie/" + robbie.id}>Edit Robbie</DropdownMenuItem>
            <DropdownMenuItem onClick={() => window.location.href="/addRobbie"}>Create Robbie</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  )
}
