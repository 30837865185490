import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button.jsx";
import {
  Form,
  FormField,
  FormLabel,
  FormControl,
  FormItem,
} from "@/components/ui/form.jsx"
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell
} from "@/components/ui/table.jsx";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb.jsx";
import { object, number} from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaSave } from "react-icons/fa";
import { FaReply } from "react-icons/fa";
import ip from "../../config_ip";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import Allfetch from "../Allfetch";
import { useAuth } from "@clerk/clerk-react"

import { Loading } from "../UI/Loading";

const contractor_id = window.location.pathname.split('/')[2]
const contra_name = window.location.pathname.split('/')[3]

export default function Assignrobbie () {

  const [robbie_id, setRobbie_id] = useState(0)
  const [ desassignRobbies, setDesassignRobbies] = useState([])
  const { getToken } = useAuth()

  const robbcont = useAllFetch(['robbcont'], '/robbcont/' + contractor_id)
  const assignrobbcont = useAllFetch(['assignrobbcont'], '/assignrobbcont/' + contractor_id)
  
  const onSubmit = async (data) => {
    const tokenu = await getToken()
    await fetch(ip.url + "/assignrobbie", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenu}`
      },
    });
    window.location.pathname = "/assignrobbie/" + contractor_id + "/" + contra_name;
  }

  const desassign = async (robbie_id, contractor_id) => {
    Allfetch('/desassign/' + robbie_id + '/' + contractor_id, setDesassignRobbies, null, null, await getToken())
    console.log(desassignRobbies)
    window.location.pathname = "/assignrobbie/" + contractor_id + "/" + contra_name;
  }

  const assignRobbieSchema = object().shape({
    robbie_id: number(),
  })

  const form = useForm({
    resolver: yupResolver(assignRobbieSchema),
    defaultValues: {
      robbie_id: 0,
      contractor_id: contractor_id,
    },
    values: {
      robbie_id,
      contractor_id,
    },
  })

  if (robbcont.isPending || assignrobbcont.isPending) {
    return <Loading />;  }

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/lcontractors"}>List Contractors</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Assing Robbies</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div>
            <p>Contractor name: {contra_name.replaceAll("%20", " ")}</p>
            <FormField
              control={form.control}
              name="robbie_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Robbies </FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={value => {setRobbie_id(value)}}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder='Select a Robbie Id'/>
                      </SelectTrigger>
                      <SelectContent>
                        {robbcont.data?.map(robbie => (
                          <SelectItem
                            key={robbie.id}
                            value={robbie.id.toString()}
                          >
                            {robbie.name_raspi}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
              )}
            />
            <div>
              <Button type="submit">
                <FaSave></FaSave>
              </Button>
            </div>
          </div>
        </form>
      </Form>
      <div>
        <Table width="25%" align="center">
          <TableHeader>
            <TableRow>
              <TableCell>Assign Robbies</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {assignrobbcont.data?.length !== 0 ?
              assignrobbcont.data?.map(robbie => (
                <TableRow align="center" key={robbie.id}>
                  <TableCell className="p-1">{robbie.name_raspi}</TableCell>
                  <TableCell className="p-1">{robbie.status}</TableCell>
                  <TableCell className="p-1">
                    <Button variant="danger" onClick={() => desassign(robbie.id, contractor_id)}>
                      <FaReply></FaReply>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            : <TableRow align="left"><TableCell>There is not assign Robbies</TableCell></TableRow>
            }
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
