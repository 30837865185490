import React from 'react';
import PropTypes from 'prop-types';
import { StatusLightStyled } from './styles';


export const StatusLight = (props) => {
  const {
    status,
    disabled,
  } = props;

  return (
    <StatusLightStyled  {...props}
    status={status}
    disabled={disabled}/>
  );
};

StatusLight.propTypes = {
  disabled: PropTypes.bool,
  status: PropTypes.string,
};

StatusLight.defaultParams = {
  disabled: false,
};
