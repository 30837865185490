import React from 'react';
import Robbie from './Robbie';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead
} from '@/components/ui/table.jsx';
import { Button } from '@/components/ui/button.jsx';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { useAllFetch } from '../CustomHooks/useAllFetch';

import { Loading } from "../UI/Loading";

const contractor_id = localStorage.getItem('temp')

const para = window.location.pathname.split('/')

export default function Robbies () {

  const robbies = useAllFetch(['robbies'], '/lrobbies')
  
  if (robbies.isPending) {
    return <Loading />;  }
  
  return (
    robbies.data.length !== 0 ?
      <div className='container'>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={window.location.href}>List Robbies</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="title-2 mt-2 ml-3">
          Robbies
        </div>
        <Table responsive="true" striped="true" size="sm" className="mt-2 mr-2 ml-3"  style={{width: '98%'}}>
          <TableHeader align="left">
            <TableRow>
              <TableHead>NAME</TableHead>
              <TableHead>SENSOR</TableHead>
              <TableHead>STATUS</TableHead>
              <TableHead>MOISTURE</TableHead>
              <TableHead>USE MOISTURE</TableHead>
              <TableHead>WS CONN</TableHead>
              <TableHead>SIM CONN</TableHead>
              <TableHead>SID</TableHead>
              <TableHead className='action-header'>ACTIONS</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {robbies.data?.map(robbie => <Robbie robbie={robbie} key={robbie.id} />)}
          </TableBody>
        </Table>
      </div>
    :
      <div className="container">
        <div className="table-title">There are no robbies created for<br/>
            <Button href={"/addRobbie/" + para[2]}>Crear Robbie</Button>
        </div>
      </div>
  )
}
