import React from "react";
import ip from "../../config_ip"
import { useForm } from "react-hook-form";
import {
	Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import { FaSave } from "react-icons/fa";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from '@clerk/clerk-react'
import { useMutation } from "@tanstack/react-query";

import { Loading } from "../UI/Loading";

const insurco_id = window.location.pathname.split('/')[2];
const contractor_id = localStorage.getItem('contractor_id')

export default function Editinsur (props) {

	const { getToken } = useAuth()

	const getinsur = useAllFetch(['getinsur'], '/getinsur/' + insurco_id)

	const mutation = useMutation({
    mutationFn: async (data) => {
      const tokenu = await getToken()
      const response = await fetch(ip.url + '/updateInsur/' + insurco_id, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )

	const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = '/linsur'
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
	}
	
	const editInsurcoSchema = object().shape({
		insurancename: string(),
		insurancephone: string()
				.min(7, "Too Short!")
				.max(11, "Too Long!"),
		insurancecountry: string()
				.min(3, "Too Short!")
				.max(50, "Too Long!"),
		insuranceaddress: string(),
		id_qbo: string(),
	})

	const form = useForm({
		resolver: yupResolver(editInsurcoSchema),
		values: {
			id: insurco_id,
			insurancename: getinsur.data?.name,
			insuranceaddress: getinsur.data?.address,
			insurancephone: getinsur.data?.phone,
			insurancecountry: getinsur.data?.country,
			id_qbo: getinsur.data?.id_qbo,
		},
	})

	if (getinsur.isPending) {
		return <Loading />;	}

	return (
		<div className="container">
			<Breadcrumb>
				<BreadcrumbList>
					<BreadcrumbItem>
						<BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbSeparator />
					<BreadcrumbItem>
						<BreadcrumbLink href={"/linsur"}>List Insurances</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbSeparator />
					<BreadcrumbItem>
						<BreadcrumbLink href={window.location.href}>Edit Contractor</BreadcrumbLink>
					</BreadcrumbItem>
				</BreadcrumbList>
			</Breadcrumb>
			<Form {...form}>
				<form onSubmit={(form.handleSubmit(onSubmit))}>
				<FormField
					control={form.control}
					name="insurancename"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Insurance Name</FormLabel>
							<FormControl>
								<Input
									placeholder="Enter insurance name"
									{...field}
									{...form.register("insurancename")}
									value={field.value}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="insuranceaddress"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Insurance Address</FormLabel>
							<FormControl>
								<Input
									placeholder="Enter insurance address"
									{...field}
									{...form.register("insuranceaddress")}
									value={field.value}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="insurancephone"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Insurance Phone</FormLabel>
							<FormControl>
								<Input
									placeholder="Enter insurance address"
									{...field}
									{...form.register("insurancephone")}
									value={field.value}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="insurancecountry"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Insurance Country</FormLabel>
							<FormControl>
								<Input
									placeholder="Enter insurance address"
									{...field}
									{...form.register("insurancecountry")}
									value={field.value}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="id_qbo"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Id_QBO</FormLabel>
							<FormControl>
								<Input
									placeholder="Enter Id_QBO"
									{...field}
									{...form.register("id_qbo")}
									value={field.value}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
				<Button type="submit" >
					<FaSave></FaSave>
				</Button>
				</form>
			</Form>
		</div>
	)
}
