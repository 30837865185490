import React from 'react';
import User from './User';
import {
	Table,
	TableHeader,
	TableBody,
	TableRow,
	TableHead
} from '@/components/ui/table.jsx';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import  { useAllFetch	} from '../CustomHooks/useAllFetch';

import { Loading } from "../UI/Loading";

const contractor_id = localStorage.getItem('contractor_id')

export default function Users () {

	const users = useAllFetch(['users'], '/lusers');

	if (users.isPending) {
		return <Loading />
	}

	return (
	 	<div>
			<Breadcrumb>
				<BreadcrumbList>
					<BreadcrumbItem>
						<BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbSeparator />
					<BreadcrumbItem>
						<BreadcrumbLink href={window.location.href}>List Users</BreadcrumbLink>
					</BreadcrumbItem>
				</BreadcrumbList>
			</Breadcrumb>
			<div className="title-2 mt-2 ml-3">
					Users
			</div>
			<Table responsive="true" striped="true" size="sm" className="mt-2 mr-2 ml-3" style={{width: '98%'}}>
					<TableHeader align="left">
							<TableRow>
									<TableHead></TableHead>
									<TableHead>NAME</TableHead>
									<TableHead>USER</TableHead>
									<TableHead>COMPANY</TableHead>
									<TableHead>ROLE</TableHead>
									<TableHead>ACTION</TableHead>
							</TableRow>
					</TableHeader>
					<TableBody>
							{users.data?.map(user => <User user={user} key={user.id} /> )}
					</TableBody>            
			</Table>
		</div>
	)
}
