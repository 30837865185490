import React from "react";
import { useForm } from "react-hook-form";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { FaSave } from "react-icons/fa";
import ip from "../../config_ip";
import { useAuth } from "@clerk/clerk-react"
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useMutation } from "@tanstack/react-query";

import { Loading } from "../UI/Loading";

const contractor_id = localStorage.getItem('temp')

export default function UserForm () {

  const { getToken } = useAuth()

  const mutation = useMutation({
    mutationFn: async (data) => {
      const tokenu = await getToken()
      const response = await fetch(ip.url + '/adduser', {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )

  const contractors = useAllFetch('newuser', '/contractors')

  function onSubmit (data) {
    mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = '/lusers'
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
  }

  const userFormSchema = object().shape({
    fullname: string(),
    username: string()
      .min(7, "Too Short!")
      .max(50, "Too Long!"),
    password: string()
      .min(8, "Too Short!")
      .max(50, "Too Long!"),
    contractor_id: number()
  })
  .required()

  const form = useForm({
    resolver: yupResolver(userFormSchema),
    values: {
      fullname: "",
      username: "",
      password: "",
      role: "member",
      contractor_id: 0,
    }
  })

  if (contractors.isPending) {
    return <Loading />;  }

  return (
    <div className="container">
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink href={"/lusers"}>List Users</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink href={window.location.href}>Add User</BreadcrumbLink>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="fullname"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Full Name user</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter area name"
                    {...field}
                    {...form.register("fullname")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>User Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter your email"
                    {...field}
                    {...form.register("username")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter a password (8-50 characters)"
                    {...field}
                    {...form.register("password")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField control={form.control}
            name="role"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Role</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder='Select Role' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="superuser">Superuser</SelectItem>
                      <SelectItem value="manager">Manager</SelectItem>
                      <SelectItem value="member">Member</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField control={form.control}
            name="contractor_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Contractors's user</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder='Select Contractor Name' />
                    </SelectTrigger>
                    <SelectContent>
                    {contractors.data?.map(contractor => (
                      <SelectItem key={contractor.id} value={contractor.id.toString()}>{contractor.name}</SelectItem>
                    ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <Button type="submit">
            <FaSave></FaSave>
          </Button>
        </form>        
      </Form>
    </div>
  )
}
