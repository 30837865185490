import React from "react";
import "./Area.scss";
import {
  TableRow,
  TableCell,
} from "@/components/ui/table.jsx";
import { StatusLight } from "../UI/StatusLight";

const contractor_id = localStorage.getItem('contractor_id')

export default function Areaadmin(props) {

  const { area } = props

  return (
    <TableRow align="left">
      <TableCell className="p-1">
        <p className="row-data-container">
          <div className="main_text"> {area.address.split(',')[0]}</div>
          <br />
          <small>{area.areadesc}</small><br />
          {contractor_id !== null ?
            <small>{area.jobid}{area.areaid}</small> : null}
        </p>
      </TableCell>
      <TableCell className="p-1">
        {area.status === 'N' ?
          <div className="inline"><StatusLight status='newarea' disabled={false} />New</div>
          : area.status === 'P' ?
            <div className="inline"><StatusLight status='paused' disabled={false} />Paused</div>
            : area.status === 'F' ?
              <div className="inline"><StatusLight status='finished' disabled={false} />Finished</div>
              : area.status === 'I' ?
                <div className="inline"><StatusLight status='progress' disabled={false} />In progress</div>
                : area.status === 'T' ?
                  <div className="inline"><StatusLight status='paused' disabled={false} />Fail Thermo</div>
                  : <div className="inline"><StatusLight status='paused' disabled={false} />Fail Amperage</div>
        }
        <p><small></small></p>
      </TableCell>
      <TableCell className="p-1">
        <p className="row-data-container">
          <div className="main_text">
            {area.humisetpoint}% Target R.H.
          </div>
          <br />
          <small>{area.dh} Dehumidifier</small><br />
          <small>{area.blowers} Air Mover</small>
        </p>
      </TableCell>
      <TableCell className="p-1">
        <p className="row-data-container">
            <div className="main_text">
              {area.name}
            </div>
            <br />
          </p>
      </TableCell>
      <TableCell className="p-1">
        <div className="row-data-container">
          <div className="main_text">
            {area.status === 'I'
              ? area.is_ws_connected === 1 || area.is_ws_connected === true ?
                  <StatusLight status='progress' disabled={false} />
                :
                  <StatusLight status='paused' disabled={false} />
              : null
            }
            {area.name_raspi}
          </div>
          <br />
        </div>
      </TableCell>
    </TableRow>
  )
}
