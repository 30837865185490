import React from "react";
import ip from "../../config_ip"
import { useForm } from "react-hook-form";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { useAuth } from "@clerk/clerk-react";
import { FaSave } from "react-icons/fa";
import { useAllFetch	} from '../CustomHooks/useAllFetch';
import { useMutation } from "@tanstack/react-query";

import { Loading } from "../UI/Loading";

const userId = window.location.pathname.split('/')[2];
const contractor_id = localStorage.getItem('contractor_id')

export default function Edituser (props) {

	const { getToken } = useAuth()

	const mutation = useMutation({
    mutationFn: async (data) => {
      const tokenu = await getToken()
      const response = await fetch(ip.url + '/updateUser/' + userId, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )

	const editUsers = useAllFetch(['editusers'], '/getuser/' + userId);
	const contractors = useAllFetch(['contractors'], '/contractors')

	const onSubmit = async (data) => {
		mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = '/lusers'
        },
        onError: function () {
            console.log('Request Error')
        }
      }
		)
	}
	
	const editUserSchema = object().shape({
		fullname: string(),
		username: string(),
		password: string()
			.min(8, "Too Short!")
			.max(64, "Too Long!"),
		contractor_id: number()
	})

	const form = useForm({
		resolver: yupResolver(editUserSchema),
		values: {
			id: editUsers.data?.id,
			fullname: editUsers.data?.fullname,
			username: editUsers.data?.username,
			password: editUsers.data?.password,
			role: editUsers.data?.role,
			contractor_id: editUsers.data?.contractor_id.toString()
		},
	})

	if (editUsers.isPending || contractors.isPending) {
		return <Loading />;	}

	return (
		<div className="container">
			<Breadcrumb>
				<BreadcrumbList>
					<BreadcrumbItem>
						<BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbSeparator />
					<BreadcrumbItem>
						<BreadcrumbLink href={"/lusers"}>List Users</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbSeparator />
					<BreadcrumbItem>
						<BreadcrumbLink href={window.location.href}>Edit User</BreadcrumbLink>
					</BreadcrumbItem>
				</BreadcrumbList>
			</Breadcrumb>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<FormField
						control={form.control}
						name="fullname"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Full Name</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter your full name"
										{...field}
										{...form.register("fullname")}
										value={field.value}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="username"
						type="email"
						render={({ field }) => (
							<FormItem>
								<FormLabel>User Name</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter your email"
										{...field}
										{...form.register("username")}
										value={field.value}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="password"
						type="password"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Password</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter your password"
										{...field}
										{...form.register("password")}
										value={field.value}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField control={form.control}
						name="role"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Role</FormLabel>
								<FormControl>
									<Select
										onValueChange={field.onChange}
										value={field.value}
									>
										<SelectTrigger>
											<SelectValue placeholder='Select Robbie Id' />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="superuser">Superuser</SelectItem>
											<SelectItem value="manager">Manager</SelectItem>
											<SelectItem value="member">Member</SelectItem>
										</SelectContent>
									</Select>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField control={form.control}
						name="contractor_id"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Companies Name</FormLabel>
								<FormControl>
									<Select
										onValueChange={field.onChange}
										value={field.value}
									>
										<SelectTrigger>
											<SelectValue placeholder='Select Company Name' />
										</SelectTrigger>
										<SelectContent>
											{contractors.data?.map((contractor) => (
												<SelectItem
													key={contractor.id}
													value={contractor.id.toString()}
												>
													{contractor.name}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</FormControl>
							</FormItem>
						)}
					/>
					<Button type="submit">
						<FaSave></FaSave>
					</Button>
				</form>
			</Form>
		</div>
	)
}