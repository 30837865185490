import React from 'react';
import Equipment from './Equipment';
import {
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
} from '@/components/ui/table.jsx';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { Button } from '@/components/ui/button.jsx';
import { useAllFetch } from '../CustomHooks/useAllFetch';

import { Loading } from "../UI/Loading";

const contractor_id = localStorage.getItem('temp')

export default function Equipments () {

  const equipments = useAllFetch(['equipments'], '/lequipments')
  
  if (equipments.isPending) {
    return <Loading />;  }

  return (
    equipments.data.length !== 0 ?
      <div className='container'>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={window.location.href}>List Equipments</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="title-2 mt-2 ml-3">
          Equipments
        </div>
        <Table responsive="true" striped="true" size="sm" className="mt-2 mr-2 ml-3"  style={{width: '98%'}}>
          <TableHeader align="left">
            <TableRow>
              <TableHead>NAME</TableHead>
              <TableHead>TYPE</TableHead>
              <TableHead>HOURLY CAD</TableHead>
              <TableHead>AMPERAGE</TableHead>
              <TableHead>XACTIMATE</TableHead>
              <TableHead>ACTIVE</TableHead>
              <TableHead className='action-header'>ACTIONS</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {equipments.data.map(equipment => <Equipment equipment={equipment} key={equipment.id} />)}
          </TableBody>
        </Table>
      </div>
    :
      <div className="container">
        <div className="table-title">There are no equipments created<br/>
            <Button href={"/addEquipment"}>Create Equipment</Button>
        </div>
      </div>
  )
}
