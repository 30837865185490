import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button.jsx";
import { Input } from "@/components/ui/input.jsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaSave } from "react-icons/fa";
import ip from "../../config_ip";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

const contractor_id = localStorage.getItem('temp')

export default function InsurForm () {

  const { getToken } = useAuth()

  const mutation = useMutation({
    mutationFn: async (data) => {
      const tokenu = await getToken()
      const response = await fetch(ip.url + '/addInsur', {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )

  const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = '/linsur'
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
  }

  const insuranceFromSchema = object().shape({
    insurancename: string(),
    insurancephone: string()
      .min(7, "Too Short!")
      .max(11, "Too Long!"),
    insurancecountry: string()
      .min(3, "Too Short!")
      .max(50, "Too Long!"),
    insuranceaddress: string(),
    id_qbo: string(),
  })
  .required()

  const form = useForm({
    resolver: yupResolver(insuranceFromSchema),
    values: {
      insurancename: "",
      insurancephone: "",
      insurancecountry: "",
      id_qbo: "",
      insuranceaddress: "",
    },
  })

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={"/linsur"}>List Insurances</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Create Insurance</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="insurancename"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Insurance Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Insurance name"
                    {...field}
                    {...form.register("insurancename")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="insuranceaddress"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Insurance Address</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Insurance address"
                    {...field}
                    {...form.register("insuranceaddress")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="insurancephone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Insurance Phone</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Insurance phone"
                    {...field}
                    {...form.register("insurancephone")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="insurancecountry"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Insurance Country</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Insurance country"
                    {...field}
                    {...form.register("insurancecountry")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="id_qbo"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Is_QBO</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Id_QBO"
                    {...field}
                    {...form.register("id_qbo")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Button type="submit">
            <FaSave></FaSave>
          </Button>
        </form>
      </Form>
    </div>
  )
}
