import React from 'react'
import ip from '../../config_ip'
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { Button } from "@/components/ui/button.jsx";
import { Input } from "@/components/ui/input.jsx";
import { object, string, number} from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { FaSave } from "react-icons/fa";
import { useAllFetch } from '../CustomHooks/useAllFetch';
import { useAuth } from '@clerk/clerk-react';
import { useMutation } from '@tanstack/react-query';

import { Loading } from "../UI/Loading";

const param_id = window.location.pathname.split('/')[2]
const contractor_id = localStorage.getItem('temp')

export default function EditParams () {
  
  const { getToken } = useAuth()

  const params = useAllFetch(['params'], '/getParam/' + param_id)
  
  const mutation = useMutation({
    mutationFn: async (data) => {
      const tokenu = await getToken()
      const response = await fetch(ip.url + '/updateParams/' + param_id, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )
  
  const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = '/lparams'
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
	}

  const editParamSchema = object().shape({
    hst: number()
      .max(30.00, "Too Long!"),
    hai: number()
      .max(99.00, "Too Long!"),
    year: string()
      .min(4, "Too Short!")
      .max(4, "Too Long!"),
    month: string()
      .min(2, "Too Short!")
      .max(2, "Too Long!"),
    day: string()
      .min(2, "Too Short!")
      .max(2, "Too Long!"),
    offpeak: number()
      .max(50.00, "Too Long!"),
    midpeak: number()
      .max(50.00, "Too Long!"),
    onpeak: number()
      .max(50.00, "Too Long!")
  })

  const form = useForm({
    resolver: yupResolver(editParamSchema),
    values: {
      id: params.data?.id,
      hst: params.data?.hst,
      hai: params.data?.hai,
      year: params.data?.year,
      month: params.data?.month,
      day: params.data?.day,
      offpeak: params.data?.offpeak,
      midpeak: params.data?.midpeak,
      onpeak: params.data?.onpeak,
    },
  })

  if (params.isPending) {
    return <Loading />;  }

  return (
    <div className='container'>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={"/lparams"}>List Parameters</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Edit Parameters</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="hst"
            render={({ field }) => (
              <FormItem>
                <FormLabel>HST</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter HST"
                    {...field}
                    {...form.register("hst")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="hai"
            render={({ field }) => (
              <FormItem>
                <FormLabel>HAI</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter HAI"
                    {...field}
                    {...form.register("hai")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="year"
            render={({ field }) => (
              <FormItem>
                <FormLabel>YEAR</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Year"
                    {...field}
                    {...form.register("year")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="month"
            render={({ field }) => (
              <FormItem>
                <FormLabel>MONTH</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Month"
                    {...field}
                    {...form.register("month")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="day"
            render={({ field }) => (
              <FormItem>
                <FormLabel>DAY</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter day"
                    {...field}
                    {...form.register("day")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="offpeak"
            render={({ field }) => (
              <FormItem>
                <FormLabel>OFF PEAK</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Off Peak"
                    {...field}
                    {...form.register("offpeak")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="midpeak"
            render={({ field }) => (
              <FormItem>
                <FormLabel>MID PEAK</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Mid Peak"
                    {...field}
                    {...form.register("midpeak")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="onpeak"
            render={({ field }) => (
              <FormItem>
                <FormLabel>ON PEAK</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter On Peak"
                    {...field}
                    {...form.register("onpeak")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Button type="submit">
            <FaSave></FaSave>
          </Button>
        </form>
      </Form>
    </div>
  )
}
